export const RouterLinks = {
    LOGIN_PAGE: "/login",

    APP: "/app",
    HOME_PAGE: "/app/home",
    MANAGER_USER: "/app/list-users",

    MANAGER_BOT: "/app/manager-bot",
    MANAGER_ACCOUNT_FACEBOOK: "/app/manager-fb-account",
    MANAGER_TASK_COLLECTIONS: "/app/manager-task-collection",
    MANAGER_TASK: "/app/manager-task/:task_collection_id",

};

export const RouterLinkPrefix = {
    LOGIN_PAGE: "/login",

    APP: "/app",
    HOME_PAGE: "/app/home",
    MANAGER_USER: "/app/list-users",

    MANAGER_BOT: "/app/manager-bot",
    MANAGER_ACCOUNT_FACEBOOK: "/app/manager-fb-account",
    MANAGER_TASK_COLLECTIONS: "/app/manager-task-collection",
    MANAGER_TASK: "/app/manager-task",
}
