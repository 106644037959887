import { UIGlobalActions } from "./ui-global/actions";
import { AuthActions } from "./auth/actions";
import { ManagerUsersActions } from "./manager-users/actions";
import { ManagerAccountsActions } from "./manager-fb-account/actions";
import { ManagerBotsActions } from "./manager-bots/actions";
import { DashBoardActions } from "./dashboard/actions";
import { ManagerTaskCollections } from "./manager-task-collections/actions";
import { ManagerTasks } from "./manager-tasks/actions";

export const useActions = () => {
    const actions = {
        UIGlobalActions,
        AuthActions,
        ManagerUsersActions,
        ManagerAccountsActions,
        ManagerBotsActions,
        DashBoardActions,
        ManagerTaskCollections,
        ManagerTasks,
    };

    return actions;
};
