import actions from "./actions";

const initState = {
    bots: [],

    pending_tasks: [],
    pd_page_index: 1,
    pd_page_size: 10,
    pd_total: 0,

    completed_tasks: [],
    cp_page_index: 1,
    cp_page_size: 8,
    cp_total: 0,

    modal_detail_task_state: false,
    task: null,

    task_id: null,

    logs: [],
    log_total: 0,
    log_page_size: 8,
    log_page_index: 1,
    log_loading: false, 
};

const reducer = (state = initState, action: any) => {
    let types = actions.type;
    let payload = action.payload;

    // console.log("[action] ", action)

    switch (action.type) {
        case types.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        case types.RELOAD:
            return state;

        case types.LOAD_LIST_BOT:
            return state;

        case types.LOAD_LIST_BOT_SUCCESS:
            return {
                ...state,
                ...{
                    bots: payload.bots,
                },
            };

        case types.LOAD_LIST_PENDING_TASK:
            return state;

        case types.LOAD_LIST_PENDING_TASK_SUCCESS:
            return {
                ...state,
                ...{
                    pending_tasks: payload.pending_tasks,
                },
            };

        case types.LOAD_COMPLETED_TASK:
            return state;

        case types.LOAD_COMPLETED_TASK_SUCCESS:
            return {
                ...state,
                ...{
                    completed_tasks: payload.completed_tasks,
                },
            };

        case types.COMPLETED_TASK_UPDATE_PAGE_SIZE:
            return {
                ...state,
                ...{
                    cp_page_size: payload.pageSize,
                    cp_page_index: 1,
                },
            };

        case types.COMPLETED_TASK_UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    cp_page_index: payload.pageIndex,
                },
            };

        case types.PENDING_TASK_UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pd_pageIndex: payload.pageIndex,
                },
            };

        case types.SET_MODAL_DETAIL_TASK_STATE:
            return {
                ...state,
                ...{
                    modal_detail_task_state: payload.state,
                },
            };

        case types.SET_CURRENT_TASK:
            return {
                ...state,
                ...{
                    task: payload.task,
                },
            };

        case types.LOAD_LIST_LOGS:
            return {
                ...state,
                ...{
                    log_loading: true,
                },
            };

        case types.LOAD_LIST_LOGS_SUCCESS:
            return {
                ...state,
                ...{
                    logs: payload.logs,
                    log_loading: false,
                },
            };

        case types.UPDATE_LOG_PAGE_INDEX:
            return {
                ...state,
                ...{
                    log_page_index: payload.pageIndex,
                },
            };

        case types.UPDATE_LOG_PAGE_SIZE:
            return {
                ...state,
                ...{
                    log_page_index: 1,
                    log_page_size: payload.pageSize,
                },
            };

        default:
            return state;
    }
};

export default reducer;
