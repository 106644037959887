import createApiServices from "../createApiServices";

const api = createApiServices();

const GetListBots = (pageSize: any = 10, pageIndex: any = 1, keyword: any = '') => {
    return api.makeAuthRequest({
        url: `manager-bots?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${keyword}`,
        method: "GET",
    });
};

const GetDetailBot = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-bots/${id}`,
        method: "GET",
    });
};

const InsertBot = (user: any) => {
    let form_data = new FormData();
    for (let key in user) {
        if (key !== "_id") form_data.append(key, user[key]);
    }

    return api.makeAuthRequest({
        url: "manager-bots",
        method: "POST",
        data: form_data,
    });
};

const UpdateBot = (id: any, account: any) => {
    let form_data = new FormData();
    for (let key in account) {
        form_data.append(key, account[key]);
    }

    return api.makeAuthRequest({
        url: `manager-bots/${id}`,
        method: "PUT",
        data: form_data,
    });
};

const RemoveBot = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-bots/${id}`,
        method: "DELETE",
    });
};

export const Bots = {
    GetListBots,
    GetDetailBot,
    InsertBot,
    RemoveBot,
    UpdateBot
};
