import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaginationComponent } from "../../components";
import { BotAllowWorkingEnum } from "../../const";
import { useActions } from "../../redux";
import { Table } from "antd";
import { CommonHelper } from "../../utils";

const columns = [
    {
        title: "TT",
        dataIndex: "stt",
    },
    {
        title: "Loại việc",
        dataIndex: "type",
        render: (type: string) => {
            return type.toUpperCase();
        },
    },
    {
        title: "Tham số",
        dataIndex: "payload",
        render: (payload: any) => {
            payload = CommonHelper.String.convertJsonToObject(payload);
            let keys = Object.keys(payload);

            return (
                <div>
                    {keys.map((key, index) => {
                        return (
                            <div key={index}>
                                <span
                                    style={{ fontWeight: 600 }}
                                >{`${key}: `}</span>
                                {payload[key]}
                            </div>
                        );
                    })}
                </div>
            );
        },
    },
    {
        title: "Trạng thái",
        dataIndex: "status",
        render: (status: any) => {
            let color = "green";

            return <div style={{ color, fontWeight: 550 }}>{status}</div>;
        },
    },
];

export const CompletedTaskDashboard = (props: any) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    let completed_tasks = useSelector(
        (state: any) => state.dashboard.completed_tasks
    );
    let loading = false;

    // pagination
    let total = useSelector((state: any) => state.dashboard.cp_total);
    let pageSize = useSelector((state: any) => state.dashboard.cp_page_size);
    let pageIndex = useSelector((state: any) => state.dashboard.cp_page_index);

    // add handle
    let items = completed_tasks;
    let data = [];
    for (let index = 0; index < items.length; index++) {
        data.push(items[index]);

        data[index] = {
            ...data[index],
            ...{
                stt: pageSize * (pageIndex - 1) + index + 1,
            },
        };
    }

    return (
        <React.Fragment>
            <div className="title">Các công việc đã hoàn thành</div>

            <Table
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={data}
                pagination={false}
                loading={loading}
                style={{
                    flex: 1,
                    cursor: "pointer"
                }}
                onRow={(data: any, index: any) => {
                    return {
                        onClick: event => {
                            dispatch(actions.DashBoardActions.setModalDetailTaskState(true))
                            dispatch(actions.DashBoardActions.loadListLogs({ task_id: data._id }))
                            dispatch(actions.DashBoardActions.setCurrentTask(data))
                        }
                    }
                }}
            />

            <div style={{ padding: 10 }}>
                <PaginationComponent
                    total={total}
                    onChange={(_page: any, _pageSize: any) => {
                        if (_pageSize !== pageSize) {
                            dispatch(
                                actions.DashBoardActions.completedTaskUpdatePageSize(
                                    _pageSize
                                )
                            );
                            return;
                        }
                        if (_page !== pageIndex) {
                            dispatch(
                                actions.DashBoardActions.completedTaskUpdatePageIndex(
                                    _page
                                )
                            );
                        }
                    }}
                    current={pageIndex}
                    pageSize={pageSize}
                    showSizeChanger={true}
                />
            </div>
        </React.Fragment>
    );
};
