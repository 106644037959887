import React, { useEffect } from "react";

import "./index.scss";

import { CurrentWorkDashboard } from "./current_work_dashboard";
import { PendingTaskDashboard } from "./pending_task_dashboard";
import { CompletedTaskDashboard } from "./completed_task_dashboard";
import { useDispatch } from "react-redux";
import { useActions } from "../../redux";
import { ModalTaskDetail } from './modal_task_detail'

export const HomePage = () => {
     // redux
     const dispatch = useDispatch();
     const actions = useActions();
 

    useEffect(() => {
        dispatch(actions.DashBoardActions.reload())
    }, [])

    return (
        <div className="HomePage">
            <div className="RowLayout" style={{ marginBottom: 10 }}>
                <div className="CurrentWorkLayout">
                    <CurrentWorkDashboard />
                </div>
                <div className="PendingTaskLayout">
                    <PendingTaskDashboard />
                </div>
            </div>

            <div className="RowLayout">
                <div className="CompletedTaskLayout">
                    <CompletedTaskDashboard />
                </div>
            </div>

            <ModalTaskDetail />
        </div>
    );
};
