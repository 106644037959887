
import { format } from "date-fns";

export const convertIsoDateToDatetime = (x: any) => {
    return format(new Date(x), "hh:mm:ss dd/MM/yyyy");
};

export const convertIsoDateToString = (x: any) => {
    return format(new Date(x), "dd/MM/yyyy");
};

export const numberDisplayThousand = (x: any) => {
    if (x === null) return "";

    let y = parseInt(x);
    if (x - y < 0.01) x = x.toFixed(0);
    else x = x.toFixed(2);

    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};