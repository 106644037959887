import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";

// helper
import {
    APIServices,
    NotificationsService,
    ValidateService,
} from "../../utils";

function* saga_LoadListUsers(action: any) {
    try {
        let params: any = action.payload.params;

        // init params
        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.manager_users.pageIndex
        );
        let pageIndex = _pageIndex;
        let _pageSize: Promise<any> = yield select(
            (state: any) => state.manager_users.pageSize
        );
        let pageSize = _pageSize;
        let _keyword: Promise<any> = yield select(
            (state: any) => state.manager_users.lastSearch
        );
        let keyword: any = _keyword;

        // real params
        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;
        if (params.keyword) keyword = params.keyword;

        // call api
        let _response: Promise<any> = yield APIServices.Users.GetListUsers(
            pageSize,
            pageIndex,
            keyword
        );
        let response: any = _response;
        let data: any = response.data;

        let users = data.items;
        yield put(actions.action.loadListUsersSuccess(users));

        // update pagesize, pageIndex
        yield put(
            actions.action.updateState({
                pageSize: data.size,
                pageIndex: data.page,
                total: data.total,
            })
        );
    } catch (ex: any) {
        console.log("[Magager User] saga_LoadListUsers Error : ", ex.message);
    }
}

function* saga_UpdagePageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListUsers({
                pageIndex,
            })
        );
    } catch (ex: any) {
        console.log("[Magager User] saga_UpdagePageIndex Error : ", ex.message);
    }
}

function* saga_UpdagePageSize(action: any) {
    try {
        let pageIndex = 1;
        let pageSize = action.payload.pageSize;

        yield put(
            actions.action.loadListUsers({
                pageIndex,
                pageSize,
            })
        );
    } catch (ex: any) {
        console.log("[Magager User] saga_UpdagePageSize Error : ", ex.message);
    }
}

function* saga_saveCurrentUser(action: any) {
    let user: any;
    try {
        let _user: Promise<any> = yield select(
            (state: any) => state.manager_users.current_user
        );
        user = _user;

        let validate = ValidateService.User.validate(user);
        if (!validate.result) {
            NotificationsService.error(validate.message);
            return;
        }
        user = validate.user;

        yield put(actions.action.setModalCurrentUserState(false));

        if (user._id) {
            // update user
            let _response: Promise<any> = yield APIServices.Users.UpdateUser(
                user._id,
                user
            );
            let response: any = _response;
            let data = response.data;

            if (data)
                NotificationsService.success("Cập nhật người dùng thành công");
            else NotificationsService.error("Cập nhật người dùng thất bại");
        } else {
            // add user
            let _response: Promise<any> = yield APIServices.Users.InsertUser(
                user
            );
            let response: any = _response;
            let data = response.data;

            if (data)
                NotificationsService.success("Thêm người dùng thành công");
            else NotificationsService.error("Thêm người dùng thất bại");
        }

        yield put(actions.action.loadListUsers({}));
    } catch (ex: any) {
        console.log("[Magager User] saga_saveCurrentUser Error : ", ex.message);

        if (user._id)
            NotificationsService.error("Cập nhật người dùng thất bại");
        else NotificationsService.error("Thêm người dùng thất bại");
    }
}

function* saga_removeUser(action: any) {
    try {
        let id = action.payload.id;

        let _response: Promise<any> = yield APIServices.Users.RemoveUser(id);
        let response: any = _response;
        let data = response.data;

        if (data) {
            yield put(actions.action.loadListUsers({}));
            NotificationsService.success("Xoá người dùng thành công");
        } else {
            NotificationsService.error("Xoá người dùng thất bại");
        }
    } catch (ex: any) {
        console.log("[Magager User] saga_removeUser Error : ", ex.message);
        NotificationsService.error("Xoá người dùng thất bại");
    }
}

function* saga_changePasswordCurrentUser(action: any) {
    try {
        let password = action.payload.password;
        let _current_user: Promise<any> = yield select(
            (state: any) => state.manager_users.current_user
        );
        let current_user: any = _current_user;

        yield APIServices.Users.ChangePassword(current_user._id, password);

        NotificationsService.success("Đổi mật khẩu thành công");
    } catch (ex: any) {
        console.log(
            "[Magager User] saga_changePasswordCurrentUser Error : ",
            ex.message
        );

        NotificationsService.error("Đổi mật khẩu thất bại");
    }
}

function* saga_searchUser(action: any) {
    try {
        let _textSearch: Promise<any> = yield select(
            (state) => state.manager_users.textSearch
        );
        let textSearch: any = _textSearch;

        yield put(
            actions.action.loadListUsers({
                keyword: textSearch,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Magager User] saga_changePasswordCurrentUser Error : ",
            ex.message
        );
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_USERS, saga_LoadListUsers);

    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdagePageIndex);
    yield takeEvery(actions.type.UPDATE_PAGE_SIZE, saga_UpdagePageSize);

    yield takeEvery(actions.type.SAVE_CURRENT_USER, saga_saveCurrentUser);
    yield takeEvery(actions.type.REMOVE_USER, saga_removeUser);
    yield takeEvery(actions.type.SEARCH_USER, saga_searchUser);

    yield takeEvery(
        actions.type.CHANGE_PASSWORD_CURRENT_USER,
        saga_changePasswordCurrentUser
    );
}

export default function* authSaga() {
    yield all([fork(listen)]);
}
