import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaginationComponent } from "../../components";
import { useActions } from "../../redux";

export const Pagination = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const total = useSelector(
        (state: any) => state.manager_task_collection.total
    );
    const pageIndex = useSelector(
        (state: any) => state.manager_task_collection.page_index
    );
    const pageSize = useSelector(
        (state: any) => state.manager_task_collection.page_size
    );

    return (
        <PaginationComponent
            total={total}
            onChange={(_page: any, _pageSize: any) => {
                if (_pageSize !== pageSize) {
                    dispatch(
                        actions.ManagerTaskCollections.updatePageSize(_pageSize)
                    );
                    return;
                }
                if (_page !== pageIndex) {
                    dispatch(
                        actions.ManagerTaskCollections.updatePageIndex(_page)
                    );
                }
            }}
            current={pageIndex}
            pageSize={pageSize}
            showSizeChanger={true}
        />
    );
};
