import React, { useEffect, useState } from 'react'
import { Select, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
    ModalComponent,
    PaginationComponent,
    SpinComponent,
} from "../../components";
import { useActions } from "../../redux";
import { AppleOutlined, AndroidOutlined } from "@ant-design/icons";
import { CommonHelper } from "../../utils";
import { LogTypes } from "../../const";

const { Option } = Select;
const { TabPane } = Tabs;

export const ModalTaskDetail = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    let logs = useSelector((state: any) => state.dashboard.logs);
    let loading = useSelector((state: any) => state.dashboard.log_loading);

    // const [modalState, setModalState] = useState(false);
    const modalState = useSelector(
        (state: any) => state.dashboard.modal_detail_task_state
    );
    const setModalState = (state: any) => {
        dispatch(actions.DashBoardActions.setModalDetailTaskState(state));
    };

    let total = useSelector((state: any) => state.dashboard.log_total);
    let pageSize = useSelector(
        (state: any) => state.dashboard.log_page_size
    );
    let pageIndex = useSelector(
        (state: any) => state.dashboard.log_page_index
    );


    let currentTask = useSelector((state: any) => state.dashboard.task)
    let [activeKey, setActiveKey] = useState("info")

    useEffect(() => {
        setActiveKey("info")
    }, [modalState])

    // render
    const _renderModalCurrentTask = () => {
        if (!currentTask) return null

        let payload = CommonHelper.String.convertJsonToObject(
            currentTask.payload
        );
        let key_payloads = Object.keys(payload);

        const updateCurrentAccount = (account: any) => {
            // dispatch(
            //     actions.ManagerAccountsActions.updateCurrentAccount(account)
            // );
        };

        return (
            <div className="InfoCurrentTab">
                {currentTask._id && (
                    <div className="RowInfo">
                        <div className="RowTitle">Id</div>
                        <div className="RowContent">{currentTask._id}</div>
                    </div>
                )}
                <div className="RowInfo">
                    <div className="RowTitle">Loại việc</div>
                    <Select
                        className="RowContentSelect"
                        value={currentTask.type}
                        onChange={(value) => {
                            // updateCurrentAccount({
                            //     type: value,
                            // });
                        }}
                    >
                        <Option value={"comment_post"}>Bình Luận</Option>
                        <Option value={"like_post"}>Like</Option>
                        <Option value={"share_post"}>Chia sẻ</Option>
                    </Select>
                </div>

                {key_payloads.map((item, index) => {
                    return (
                        <div className="RowInfo">
                            <div className="RowTitle">{item}</div>
                            <div className="RowContent">{payload[item]}</div>
                        </div>
                    );
                })}

                {/* <div className="RowInfo">
                    <div className="RowTitle">Tham số</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentAccount.UID}
                        onChange={(e) => {
                            updateCurrentAccount({
                                UID: e.target.value,
                            });
                        }}
                    />
                </div> */}
            </div>
        );
    };

    const _renderListLogs = () => {
        return (
            <div className="ListLogsTab">
                <div className="ListLogs">
                    {logs.map((log: any, index: any) => {
                        let type_log = CommonHelper.String.getTypeOfLog(
                            log.content
                        );
                        let color = "black";

                        switch (type_log) {
                            case LogTypes.SUCCESS:
                                color = "#00b33c";
                                break;

                            case LogTypes.ERROR:
                                color = "#ff6600";
                                break;

                            case LogTypes.NORMAL:
                                color = "#3399ff";
                                break;

                            default:
                                color = "black";
                                break;
                        }

                        return (
                            <div
                                className="LogItem"
                                style={{
                                    borderLeftColor: color,
                                }}
                                key={index}
                            >
                                <div className="Title">{log.content}</div>
                                <div className="Time">
                                    {CommonHelper.Date.convertIsoDateToDatetime(
                                        log.timestamp
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                <PaginationComponent
                    total={total}
                    onChange={(_page: any, _pageSize: any) => {
                        if (_pageSize !== pageSize) {
                            dispatch(
                                actions.DashBoardActions.updateLogPageSize(
                                    _pageSize
                                )
                            );
                            return;
                        }
                        if (_page !== pageIndex) {
                            dispatch(
                                actions.DashBoardActions.updateLogPageIndex(_page)
                            );
                        }
                    }}
                    current={pageIndex}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    simple
                />
            </div>
        );
    };

    
    return (
        <ModalComponent
            // title={"Chi tiết công việc"}
            title=""
            isModalVisible={modalState}
            onOk={() => {
                setModalState(false)
                // dispatch(actions.ManagerAccountsActions.saveCurrentAccount());
            }}
            onCancel={() => {
                setModalState(false);
            }}
            footer={null}
            // cancelText="Huỷ"
            // okText="Lưu"
            bodyStyle={{
                backgroundColor: "white",
            }}
            style={{
                cursor: "pointer"
            }}
        >
            <Tabs
                activeKey={activeKey}
                tabBarStyle={{ color: "black", borderBottom: "black" }}
                onChange={(key) => {
                    setActiveKey(key)
                }}
            >
                <TabPane tab={<span>Thông tin</span>} key="info">
                    {_renderModalCurrentTask()}
                </TabPane>
                <TabPane tab={<span>Logs ({total})</span>} key="logs">
                    {/* {loading ? <SpinComponent /> : _renderListLogs()} */}
                    {loading ? (
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            {<SpinComponent />}
                        </div>
                    ) : (
                        _renderListLogs()
                    )}
                </TabPane>
            </Tabs>
        </ModalComponent>
    );
}