import { Select } from "antd";
import React from "react";
import { FiUploadCloud } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { ListRoles } from "../../const";
import { useActions } from "../../redux";
import actions from "../../redux/ui-global/actions";
import { APIServices, NotificationsService } from "../../utils";
import { ModalComponent } from "../modal";

const { Option } = Select;

export const ModalUserInfo = (props: any = {}) => {
    let { visible, setVisible } = props;
    const dispatch = useDispatch();
    const actions = useActions();

    let currentUser = useSelector((state: any) => state.auth.userInfo);

    const avatar = APIServices.Images.getImageUrl(currentUser.avatar);

    const updateCurrentUser = (user: any = {}) => {
        dispatch(actions.AuthActions.updateCurrentUserInfo(user));
    };

    const handleImageChange = (e: any) => {
        e.preventDefault();

        for (let index = 0; index < e.target.files.length; index++) {
            let file = e.target.files[index];
            let reader = new FileReader();

            reader.readAsDataURL(file);

            if (file.type.indexOf("image/") === 0) {
                reader.onloadend = () => {
                    let avatar = reader.result;
                    updateCurrentUser({
                        avatar,
                        file,
                    });
                };
            } else {
                NotificationsService.error("File không đúng định dạng");
            }
        }
    };

    return (
        <ModalComponent
            title="Thông tin cá nhân"
            isModalVisible={visible}
            onOk={() => {
                dispatch(actions.AuthActions.saveCurrentUserInfo());
                setVisible(false);
            }}
            onCancel={() => {
                setVisible(false);
            }}
            cancelText="Huỷ"
            okText="Lưu"
            // width={360}
        >
            <div className="ModalCurrentUser">
                <div className="RowAvatarInfo">
                    {avatar != "" && (
                        <img
                            className="AvatarImage"
                            src={avatar}
                            alt="avatar"
                        />
                    )}

                    <input
                        type="file"
                        name="file"
                        id="file"
                        style={{
                            display: "none",
                        }}
                        onChange={(e) => handleImageChange(e)}
                    />

                    <label htmlFor={"file"} className="Button">
                        <FiUploadCloud className="Icon" />
                        Chọn avatar
                    </label>
                </div>

                <div className="RowInfo">
                    <div className="RowTitle">Họ và tên</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentUser.fullname}
                        onChange={(e) => {
                            updateCurrentUser({
                                fullname: e.target.value,
                            });
                        }}
                    />
                </div>

                {currentUser._id == null && (
                    <>
                        <div className="RowInfo">
                            <div className="RowTitle">Mật khẩu</div>
                            <input
                                type="password"
                                className="RowContent"
                                value={currentUser.password}
                                onChange={(e) => {
                                    updateCurrentUser({
                                        password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="RowInfo">
                            <div className="RowTitle">Xác nhận mật khẩu</div>
                            <input
                                type="password"
                                className="RowContent"
                                value={currentUser.confirm_password}
                                onChange={(e) => {
                                    updateCurrentUser({
                                        confirm_password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </>
                )}

                <div className="RowInfo">
                    <div className="RowTitle">Giới tính</div>
                    <Select
                        className="RowContentSelect"
                        value={currentUser.sex}
                        onChange={(value) => {
                            updateCurrentUser({
                                sex: value,
                            });
                        }}
                    >
                        <Option value={0}>Nữ</Option>
                        <Option value={1}>Nam</Option>
                    </Select>
                </div>

                <div className="RowInfo">
                    <div className="RowTitle">Địa chỉ</div>
                    <textarea
                        className="RowAreaContent"
                        value={currentUser.address}
                        onChange={(e) => {
                            updateCurrentUser({
                                address: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>
        </ModalComponent>
    );
};
