import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";

// helper
import {
    APIServices,
    NotificationsService,
    ValidateService,
} from "../../utils";

function* saga_LoadListAccounts(action: any) {
    try {
        let params: any = action.payload.params;

        // init params
        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.manager_accounts.pageIndex
        );
        let pageIndex = _pageIndex;
        let _pageSize: Promise<any> = yield select(
            (state: any) => state.manager_accounts.pageSize
        );
        let pageSize = _pageSize;
        let _keyword: Promise<any> = yield select(
            (state: any) => state.manager_accounts.lastSearch
        );
        let keyword: any = _keyword;

        // real params
        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.pageSize) pageSize = params.pageSize;
        if (params.keyword) keyword = params.keyword;

        // call api
        let _response: Promise<any> = yield APIServices.Accounts.GetListAccounts(
            pageSize,
            pageIndex,
            keyword
        );
        let response: any = _response;
        let data: any = response.data;

        let users = data.items;
        yield put(actions.action.loadListAccountsSuccess(users));

        // update pagesize, pageIndex
        yield put(
            actions.action.updateState({
                pageSize: data.size,
                pageIndex: data.page,
                total: data.total,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Magager Account] saga_LoadListAccounts Error : ",
            ex.message
        );
    }
}

function* saga_UpdagePageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListAccounts({
                pageIndex,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Magager Account] saga_UpdagePageIndex Error : ",
            ex.message
        );
    }
}

function* saga_UpdagePageSize(action: any) {
    try {
        let pageIndex = 1;
        let pageSize = action.payload.pageSize;

        yield put(
            actions.action.loadListAccounts({
                pageIndex,
                pageSize,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Magager Account] saga_UpdagePageSize Error : ",
            ex.message
        );
    }
}

function* saga_saveCurrentAccount(action: any) {
    let account: any;
    try {
        let _account: Promise<any> = yield select(
            (state: any) => state.manager_accounts.current_account
        );
        account = _account;

        let validate = ValidateService.Account.validate(account);
        if (!validate.result) {
            NotificationsService.error(validate.message);
            return;
        }
        account = validate.account;

        yield put(actions.action.setModalCurrentAccountState(false));

        if (account._id) {
            // update user
            let _response: Promise<any> = yield APIServices.Accounts.UpdateAccount(
                account._id,
                account
            );
            let response: any = _response;
            let data = response.data;
            if (data)
                NotificationsService.success("Cập nhật tài khoản fb thành công");
            else NotificationsService.error("Cập nhật tài khoản fb thất bại");
        } else {
            // add user
            let _response: Promise<any> = yield APIServices.Accounts.InsertAccount(
                account
            );
            let response: any = _response;
            let data = response.data;

            if (data)
                NotificationsService.success("Thêm tài khoản fb thành công");
            else NotificationsService.error("Thêm tài khoản fb thất bại");
        }

        yield put(actions.action.loadListAccounts({}));
    } catch (ex: any) {
        console.log(
            "[Magager Account] saga_saveCurrentAccount Error : ",
            ex.message
        );

        if (account._id)
            NotificationsService.error("Cập nhật tài khoản fb thất bại");
        else NotificationsService.error("Thêm tài khoản fb thất bại");
    }
}

function* saga_removeAccount(action: any) {
    try {
        let id = action.payload.id;

        let _response: Promise<any> = yield APIServices.Accounts.RemoveAccount(
            id
        );
        let response: any = _response;
        let data = response.data;

        if (data) {
            yield put(actions.action.loadListAccounts({}));
            NotificationsService.success("Xoá tài khoản fb thành công");
        } else {
            NotificationsService.error("Xoá tài khoản fb thất bại");
        }
    } catch (ex: any) {
        console.log("[Magager Account] saga_removeUser Error : ", ex.message);
        NotificationsService.error("Xoá tài khoản fb thất bại");
    }
}

function* saga_searchAccount(action: any) {
    try {
        let _textSearch: Promise<any> = yield select(
            (state) => state.manager_users.textSearch
        );
        let textSearch: any = _textSearch;

        yield put(
            actions.action.loadListAccounts({
                keyword: textSearch,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Magager Account] saga_searchAccount Error : ",
            ex.message
        );
    }
}

function* saga_ResetStatusAllAccount(action: any){
    try {
        yield APIServices.Accounts.ResetStatusAllAccount()

        yield put(
            actions.action.loadListAccounts({
            })
        );
    } catch (ex: any) {
        console.log(
            "[Magager Account] saga_ResetStatusAllAccount Error : ",
            ex.message
        );
    }
}

function* listen() {
    yield takeEvery(actions.type.LOAD_LIST_ACCOUNTS, saga_LoadListAccounts);

    yield takeEvery(actions.type.UPDATE_PAGE_INDEX, saga_UpdagePageIndex);
    yield takeEvery(actions.type.UPDATE_PAGE_SIZE, saga_UpdagePageSize);

    yield takeEvery(actions.type.SAVE_CURRENT_ACCOUNT, saga_saveCurrentAccount);
    yield takeEvery(actions.type.REMOVE_ACCOUNT, saga_removeAccount);
    yield takeEvery(actions.type.SEARCH_ACCOUNT, saga_searchAccount);

    yield takeEvery(actions.type.RESET_STATUS_ALL_ACCOUNT, saga_ResetStatusAllAccount)
}

export default function* authSaga() {
    yield all([fork(listen)]);
}
