import actions from "./actions"

const initState = {
    collapse: false
}

const Reducer = (state = initState, action: any) => {
    switch (action.type){
        case actions.types.SET_COLLAPSE:
            return {
                ...state,
                ...{
                    collapse: action.payload.collapse
                }
            }

        default:
            return state
    }
}

export default Reducer