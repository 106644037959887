import actions from "./actions";

const default_task = {
    _id: null,
    task_collection_id: null,
    type: "",
    status: "",
    payload: "",
    created_date: 1620298435243,
    last_update: 1620298474549,
};

const initState = {
    task_collection_id: null,

    tasks: [],
    loading: false,
    page_size: 10,
    page_index: 1,
    text_search: "",
    last_search: "",

    current_task: [],
    task_id: null,

    logs: [],
    log_total: 0,
    log_page_size: 8,
    log_page_index: 1,
    log_loading: false,

    modalCurrentTaskState: false,

    task_collection: {
        name: ''
    }
};

const reducer = (state = initState, action: any) => {
    let types = actions.type;
    let payload = action.payload;

    switch (action.type) {
        case types.UPDATE_STATE:
            return {
                ...state,
                ...payload.state,
            };

        case types.LOAD_LIST_TASK:
            return {
                ...state,
                ...{
                    loading: true
                }
            };

        case types.LOAD_LIST_TASK_SUCCESS:
            return {
                ...state,
                ...{
                    tasks: payload.tasks,
                    loading: false
                },
            };

        case types.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    page_index: payload.pageIndex,
                },
            };

        case types.UPDATE_PAGE_SIZE:
            return {
                ...state,
                ...{
                    page_index: 1,
                    page_size: payload.pageSize,
                },
            };

        case types.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    text_search: payload.textSearch,
                },
            };

        case types.SEARCH_TASK:
            return {
                ...state,
                ...{
                    pageIndex: 1,
                    last_search: payload.text_search,
                },
            };

        case types.SET_DEFAULT_CURRENT_TASK:
            return {
                ...state,
                ...{
                    current_task: payload.task,
                },
            };

        case types.SET_CURRENT_TASK:
            return {
                ...state,
                ...{
                    current_task: payload.task,
                },
            };

        case types.UPDATE_CURRENT_TASK:
            return {
                ...state,
                ...{
                    current_task: {
                        ...state.current_task,
                        ...payload.task
                    }
                },
            };

        case types.REMOVE_TASK:
            return state;

        case types.SAVE_CURRENT_TASK:
            return state;

        case types.LOAD_LIST_LOGS:
            return {
                ...state,
                ...{
                    log_loading: true
                }
            };

        case types.LOAD_LIST_LOGS_SUCCESS:
            return {
                ...state,
                ...{
                    logs: payload.logs,
                    log_loading: false
                },
            };

        case types.UPDATE_LOG_PAGE_INDEX:
            return {
                ...state,
                ...{
                    log_page_index: payload.pageIndex
                }
            }

        case types.UPDATE_LOG_PAGE_SIZE:
            return {
                ...state,
                ...{
                    log_page_index: 1,
                    log_page_size: payload.pageSize
                }
            }

        case types.SET_MODAL_CURRENT_TASK:
            return {
                ...state,
                ...{
                    modalCurrentTaskState: payload.state
                }
            }

        case types.LOAD_TASK_COLLECTION_INFO:
            return state

        case types.LOAD_TASK_COLLECTION_INFO_SUCCESS:
            return {
                ...state,
                ...{
                    task_collection: payload.task_collection
                }
            }

        case types.ADD_TASK:
            return state 

        default:
            return state;
    }
};

export default reducer;
