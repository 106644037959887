import React, { useEffect, useState } from 'react';
import {
    ModalComponent,
    PaginationComponent,
    SpinComponent,
} from '../../components';
import { Button, Form, Input, Select, Tabs } from 'antd';
import {
    GetPayloadByTaskType,
    LikePayload,
    RouterLinks,
    SetPayloadByType,
    TaskTypeEnum,
} from '../../const';
import moment from 'moment';
import { FaCheck, FaSave, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from 'rc-textarea';
import { useActions } from '../../redux';

const { Option } = Select;

const default_task = {
    type: TaskTypeEnum.LIKE_POST,
    payload: LikePayload,
    task_collection_id: '',
};

export const ModalAddTask = (props: any) => {
    const [payload, setPayload] = useState<any>(GetPayloadByTaskType());
    const [task, currentTask] = useState<any>(default_task);

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const actions = useActions();

    const task_collection = useSelector(
        (state: any) => state.manager_task.task_collection,
    );

    // did mount
    useEffect(() => {
        // form.setFieldsValue({ task, ...task.payload });
        form.setFieldsValue({ type: default_task.type });
        form.setFieldsValue({ content: '', number: '', post_id: '' });
    }, []);

    // handle
    const onFinish = (values: any) => {
        let payload: any = SetPayloadByType(values.type, values);

        let task = {
            task_collection_id: task_collection._id,
            type: values.type,
            payload,
        };

        console.log('[form values]: ', task);

        dispatch(actions.ManagerTasks.addTask(task));
        
        if (props.setVisible)
            props.setVisible(false)
    };

    const onFinishFailed = (errorInfo: any) => {};

    let key_payloads = Object.keys(payload);

    return (
        <ModalComponent
            {...props}
            title="Thêm việc"
            width={600}
            onClick={() => {}}
            className="ModalAddTask"
            okText="Thêm nhiệm vụ"
            cancelText="Hủy"
            footer={[
                <Button
                    onClick={() => {
                        if (props.onCancel) props.onCancel();
                    }}
                >
                    Hủy
                </Button>,
                <Button form="ModalAddTask" type="primary" htmlType="submit">
                    Thêm nhiệm vụ
                </Button>,
            ]}
        >
            <Form
                name="ModalAddTask"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="ModalUserInfo"
                form={form}
                colon={false}
            >
                <Form.Item
                    label="Nhiệm vụ"
                    // name="type"
                    style={{
                        marginBottom: 10,
                    }}
                >
                    {task_collection.name}
                </Form.Item>

                <Form.Item
                    label="Loại nhiệm vụ"
                    name="type"
                    style={{
                        marginBottom: 10,
                    }}
                    rules={[
                        {
                            required: true,
                            message: 'Loại nhiệm vụ không được để trống',
                        },
                    ]}
                >
                    <Select
                        onChange={(value: any) => {
                            let payload = GetPayloadByTaskType(value);
                            setPayload(payload);
                        }}
                    >
                        <Option value={'comment_post'}>
                            Bình Luận bài viết
                        </Option>
                        <Option value={'like_post'}>Thích bài viết</Option>
                        <Option value={'share_post'}>Chia sẻ bài viết</Option>
                    </Select>
                </Form.Item>

                {key_payloads.map((entity: any, index: any) => {
                    return (
                        <Form.Item
                            label={entity}
                            name={entity}
                            style={{
                                marginBottom: 10,
                            }}
                            rules={[
                                {
                                    required: true,
                                    message: `Xin mời nhập ${entity}`,
                                },
                            ]}
                        >
                            {entity == 'content' ? (
                                <Input.TextArea />
                            ) : (
                                <Input />
                            )}
                        </Form.Item>
                    );
                })}
            </Form>
        </ModalComponent>
    );
};
