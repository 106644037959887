import { ListBotAllowWorkingEnum } from "../../const";

const correctBot = (bot: any) => {
    bot.name = bot.name ? bot.name.trim() : "";
    bot.username = bot.username ? bot.username.trim() : "";
    bot.password = bot.password ? bot.password.trim() : "";
    bot.confirm_password = bot.confirm_password ? bot.confirm_password.trim() : "";

    return bot;
};

const checkAllowWorking = (bot: any) => {
    let allow_working = bot.allow_working;

    for (let index = 0; index < ListBotAllowWorkingEnum.length; index++)
        if (allow_working === ListBotAllowWorkingEnum[index]) return true;

    return false;
};

export const validate = (bot: any) => {
    bot = correctBot(bot);

    const ErrorResult = (message = "") => {
        return {
            bot,
            result: false,
            message,
        };
    };

    if (bot.name === "") return ErrorResult("Tên bot không được để trống");
    if (bot.username === "")
        return ErrorResult("Tên đăng nhập bot không được để trống");

    if (!bot._id) {
        if (bot.password === "")
            return ErrorResult("Mật khẩu bot không được để trống");

        if (bot.password !== bot.confirm_password)
            return ErrorResult("Xác nhận mật khẩu không chính xác");
    }

    if (!checkAllowWorking(bot))
        return ErrorResult("Quyền làm việc của bot không chính xác ");

    return {
        bot,
        result: true,
        message: "",
    };
};
