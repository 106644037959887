import React from "react";
import { Table } from "antd";
import { FaEye, FaRegEdit, FaUserSecret } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";
import { showConfirm } from "../../components";

const columns = [
    {
        title: "TT",
        dataIndex: "stt",
    },
    {
        title: "UID",
        dataIndex: "UID",
    },
    {
        title: "Tên đăng nhập",
        dataIndex: "USERNAME",
    },
    {
        title: "Mật khẩu",
        dataIndex: "PASSWORD",
    },
    // {
    //     title: "SECRET KEY",
    //     dataIndex: "SECRET",
    // },
    {
        title: "Trạng thái",
        dataIndex: "status",
        render: (status: any) => {
            let color = "black;";
            if (status == "LIVE") color = "green";
            if (status == "DEAD") color = "red";

            return (
                <div
                    style={{
                        color,
                        fontWeight: 600,
                    }}
                >
                    {status}
                </div>
            );
        },
    },
    {
        title: "Hành động",
        width: 200,
        render: (entity: any) => {
            return (
                <div className="ListActions">
                    {/* <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            // if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaUserSecret className="Icon" />
                        Copy 2fa code
                    </div> */}
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaEye className="Icon" />
                        Xem
                    </div>
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#ff3300",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleRemove) entity.handleRemove();
                        }}
                    >
                        <FiTrash2 className="Icon" />
                        Xoá
                    </div>
                </div>
            );
        },
    },
];

export const TableListAccout = (props: any) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const loading = useSelector((state: any) => state.manager_accounts.loading);
    const list_accounts = useSelector(
        (state: any) => state.manager_accounts.list_accounts
    );

    const setModalState = (state: any) => {
        dispatch(
            actions.ManagerAccountsActions.setModalCurrentAccountState(state)
        );
    };

    // current account
    const setCurrentAccount = (account: any) => {
        dispatch(actions.ManagerAccountsActions.setCurrentAccount(account));
    };

    // pagination
    const pageIndex = useSelector(
        (state: any) => state.manager_accounts.pageIndex
    );
    const pageSize = useSelector(
        (state: any) => state.manager_accounts.pageSize
    );

    // add handle
    let items = list_accounts;
    let data = [];
    for (let index = 0; index < items.length; index++) {
        data.push(items[index]);

        data[index] = {
            ...data[index],
            ...{
                stt: pageSize * (pageIndex - 1) + index + 1,
                handleEdit: () => {
                    setCurrentAccount(list_accounts[index]);
                    setModalState(true);
                },
                handleRemove: () => {
                    setCurrentAccount(list_accounts[index]);
                    showConfirm({
                        title: "Bạn chắc chắn xoá tài khoản này?",
                        content: "",
                        onOk: () => {
                            dispatch(
                                actions.ManagerAccountsActions.removeAccount(
                                    list_accounts[index]._id
                                )
                            );
                        },
                        onCancel: () => {},
                    });
                },
            },
        };
    }

    return (
        <Table
            columns={columns}
            rowKey={(record) => record._id}
            dataSource={data}
            pagination={false}
            loading={loading}
            style={{
                flex: 1,
            }}
        />
    );
};
