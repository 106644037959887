import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ModalComponent } from "../../components";
import { useActions } from "../../redux";

const { Option } = Select;

export const ModalCurrentAccountComponent = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    // const [modalState, setModalState] = useState(false);
    const modalState = useSelector(
        (state: any) => state.manager_accounts.modal_current_account_state
    );
    const setModalState = (state: any) => {
        dispatch(
            actions.ManagerAccountsActions.setModalCurrentAccountState(state)
        );
    };

    // render
    const _renderModalCurrentAccount = () => {
        // current account
        let currentAccount = useSelector(
            (state: any) => state.manager_accounts.current_account
        );
        const updateCurrentAccount = (account: any) => {
            dispatch(
                actions.ManagerAccountsActions.updateCurrentAccount(account)
            );
        };

        return (
            <div className="ModalCurrentUser">
                {currentAccount._id && (
                    <div className="RowInfo">
                        <div className="RowTitle">_id</div>
                        <div className="RowContent">{currentAccount._id}</div>
                    </div>
                )}
                <div className="RowInfo">
                    <div className="RowTitle">UID</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentAccount.UID}
                        onChange={(e) => {
                            updateCurrentAccount({
                                UID: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">USERNAME</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentAccount.USERNAME}
                        onChange={(e) => {
                            updateCurrentAccount({
                                USERNAME: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">PASSWORD</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentAccount.PASSWORD}
                        onChange={(e) => {
                            updateCurrentAccount({
                                PASSWORD: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">SECRET</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentAccount.SECRET}
                        onChange={(e) => {
                            updateCurrentAccount({
                                SECRET: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">COOKIES</div>
                    <textarea
                        // type="text"
                        style={{ height: 150 }}
                        className="RowContent"
                        value={currentAccount.COOKIES}
                        onChange={(e) => {
                            updateCurrentAccount({
                                COOKIES: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">ISP</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentAccount.ISP}
                        onChange={(e) => {
                            updateCurrentAccount({
                                ISP: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Trạng thái</div>
                    <Select
                        className="RowContentSelect"
                        value={currentAccount.status}
                        onChange={(value) => {
                            updateCurrentAccount({
                                status: value,
                            });
                        }}
                    >
                        <Option value={"DEAD"}>DEAD</Option>
                        <Option value={"LIVE"}>LIVE</Option>
                        <Option value={"UNKNOWN"}>UNKNOWN</Option>
                    </Select>
                </div>
            </div>
        );
    };

    return (
        <ModalComponent
            title={"Chi tiết tài khoản"}
            isModalVisible={modalState}
            onOk={() => {
                dispatch(actions.ManagerAccountsActions.saveCurrentAccount());
            }}
            onCancel={() => {
                setModalState(false);
            }}
            cancelText="Huỷ"
            okText="Lưu"
        >
            {_renderModalCurrentAccount()}
        </ModalComponent>
    );
};
