import actions from './actions'

const default_task_collection = {
    _id: null,
    name: '',
    description: ''
}

const initState = {
    task_collections: [],
    loading: false,

    total: 0,
    page_size: 10,
    page_index: 1,

    text_search: '',
    last_search: '',

    current_task_collection: default_task_collection,

    modalCurrentTaskCollectionState: false
}

const reducer = (state = initState, action: any) => {
    let types = actions.type
    let payload = action.payload

    switch (action.type){
        case types.UPDATE_STATE:
            return {
                ...state,
                ...payload.state 
            }

        case types.LOAD_TASK_COLLECTIONS:
            return {
                ...state,
                ...{
                    loading: true
                }
            } 

        case types.LOAD_TASK_COLLECTIONS_SUCCESS:
            return {
                ...state,
                ...{
                    task_collections: payload.task_collections,
                    loading: false
                }
            }

        case types.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    page_index: payload.pageIndex
                }
            }

        case types.UPDATE_PAGE_SIZE:
            return {
                ...state,
                ...{
                    page_index: 1,
                    page_size: payload.pageSize
                }
            }

        case types.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    text_search: payload.textSearch
                }
            }

        case types.SEARCH_TASK_COLLECTION:
            return {
                ...state,
                ...{
                    last_search: state.text_search
                }
            }

        // current task collection
        case types.SET_DEFAULT_CURRENT_TASK_COLLECTION:
            return {
                ...state,
                ...{
                    current_task_collection: default_task_collection
                }
            }

        case types.SET_CURRENT_TASK_COLLECTION:
            return {
                ...state,
                ...{
                    current_task_collection: payload.task_collection
                }
            }

        case types.UPDATE_CURRENT_TASK_COLLECTION:
            return {
                ...state,
                ...{
                    current_task_collection: {
                        ...state.current_task_collection,
                        ...payload.task_collection
                    }
                }
            }

        case types.SAVE_CURRENT_TASK_COLLECTION:
            return state 

        case types.REMOVE_TASK_COLLECTION:
            return state

        case types.SET_MODAL_CURRENT_TASK_COLLECTION_STATE:
            return {
                ...state,
                ...{
                    modalCurrentTaskCollectionState: payload.state
                }
            }

        default:
            return state 
    }
}

export default reducer