import { all, takeEvery, fork, put, select, delay } from "redux-saga/effects";
import {
    APIServices,
    NotificationsService,
    ValidateService,
} from "../../utils";
import actions from "./actions";

function* saga_LoadTaskCollections(action: any) {
    try {
        let { params } = action.payload;

        let _pageSize: Promise<any> = yield select(
            (state: any) => state.manager_task_collection.page_size
        );
        let pageSize: any = _pageSize;

        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.manager_task_collection.page_index
        );
        let pageIndex: any = _pageIndex;

        let _textSearch: Promise<any> = yield select(
            (state: any) => state.manager_task_collection.last_search
        );
        let textSearch: any = _textSearch;

        // params
        if (params.pageSize) pageSize = params.pageSize;
        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.textSearch) textSearch = params.textSearch;

        let _req: Promise<any> =
            yield APIServices.TaskCollections.GetListTaskCollections(
                pageSize,
                pageIndex,
                textSearch
            );
        let req: any = _req;
        let data = req.data;

        let { items, total } = data;

        yield put(
            actions.action.updateState({
                total: total,
            })
        );
        yield put(actions.action.loadTaskCollectionsSuccess(items));
    } catch (ex: any) {
        console.log(
            "[Manager TaskCollection Saga] saga_LoadTaskCollections error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadTaskCollections({
                pageIndex,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Manager TaskCollection Saga] saga_UpdatePageIndex error : ",
            ex.message
        );
    }
}

function* saga_UpdatePageSize(action: any) {
    try {
        let pageIndex = 1;
        let pageSize = action.payload.pageSize;

        yield put(
            actions.action.loadTaskCollections({
                pageIndex,
                pageSize,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Manager TaskCollection Saga] saga_UpdatePageSize error : ",
            ex.message
        );
    }
}

function* saga_SearchTaskCollection(action: any) {
    try {
        let _textSearch: Promise<any> = yield select(
            (state: any) => state.manager_task_collection.text_search
        );
        let textSearch: any = _textSearch;

        yield put(
            actions.action.loadTaskCollections({
                textSearch,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Manager TaskCollection Saga] saga_SearchTaskCollection error : ",
            ex.message
        );
    }
}

function* saga_SaveCurrentTaskCollection(action: any) {
    let entity: any;
    try {
        let _user: Promise<any> = yield select(
            (state: any) => state.manager_task_collection.current_task_collection
        );
        entity = _user;

        let validate = ValidateService.TaskCollection.validate(entity);
        if (!validate.result) {
            NotificationsService.error(validate.message);
            return;
        }
        entity = validate.task_collection;

        yield put(actions.action.setModalCurrentTaskCollectionState(false));

        if (entity._id) {
            let _response: Promise<any> =
                yield APIServices.TaskCollections.UpdateTaskCollection(
                    entity._id,
                    entity
                );
            let response: any = _response;
            let data = response.data;

            if (data)
                NotificationsService.success("Cập nhật đầu việc thành công");
            else NotificationsService.error("Cập nhật đầu việc thất bại");
        } else {
            let _response: Promise<any> =
                yield APIServices.TaskCollections.InsertTaskCollection(entity);
            let response: any = _response;
            let data = response.data;

            if (data) NotificationsService.success("Thêm đầu việc thành công");
            else NotificationsService.error("Thêm đầu việc thất bại");
        }

        yield put(actions.action.loadTaskCollections({}));
    } catch (ex: any) {
        console.log(
            "[Manager TaskCollection Saga] saga_SaveCurrentTaskCollection error : ",
            ex.message
        );

        if (entity._id)
            NotificationsService.error("Cập nhật đầu việc thất bại");
        else NotificationsService.error("Thêm đầu việc thất bại");
    }
}

function* saga_RemoveTaskCollection(action: any) {
    try {
        let { id } = action.payload;
        yield APIServices.TaskCollections.RemoveTaskCollection(id);

        NotificationsService.success("Xoá đầu việc thành công");
        yield put(actions.action.loadTaskCollections({}));
    } catch (ex: any) {
        console.log(
            "[Manager TaskCollection Saga] saga_RemoveTaskCollection error : ",
            ex.message
        );

        NotificationsService.error("Xoá đầu việc thất bại");
    }
}

function* listen() {
    const types = actions.type;

    yield takeEvery(types.LOAD_TASK_COLLECTIONS, saga_LoadTaskCollections);
    yield takeEvery(types.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
    yield takeEvery(types.UPDATE_PAGE_SIZE, saga_UpdatePageSize);
    yield takeEvery(types.SEARCH_TASK_COLLECTION, saga_SearchTaskCollection);

    yield takeEvery(
        types.SAVE_CURRENT_TASK_COLLECTION,
        saga_SaveCurrentTaskCollection
    );
    yield takeEvery(types.REMOVE_TASK_COLLECTION, saga_RemoveTaskCollection);
}

export default function* managerTaskCollectionSaga() {
    yield all([fork(listen)]);
}
