import { ListRoles } from "../../const";

const correctUser = (user: any) => {
    user.fullname = user.fullname ? user.fullname.trim() : "";
    user.avatar = user.avatar ? user.avatar.trim() : "";
    user.password = user.password ? user.password.trim() : "";
    user.confirm_password = user.confirm_password
        ? user.confirm_password.trim()
        : "";
    user.address = user.address ? user.address.trim() : "";
    user.role = user.role ? user.role.trim() : "";

    return user;
};

const checkRole = (user: any) => {
    let role = user.role;

    for (let index = 0; index < ListRoles.length; index++)
        if (role === ListRoles[index]) return true;

    return false;
};

export const validate = (user: any) => {
    user = correctUser(user);

    const ErrorResult = (message = "") => {
        return {
            user,
            result: false,
            message,
        };
    };

    if (user.fullname === "")
        return ErrorResult("Họ và tên không được để trống");
    if (user.email === "") return ErrorResult("Email không được để trống");

    if (!checkRole(user)) return ErrorResult("Loại quyền không đúng");

    if (!user._id) {
        // if (!user.file)
        //     return ErrorResult("Người dùng chưa được chọn avatar")
        if (user.password === "")
            return ErrorResult("Mật khẩu không được để trống");
        if (user.password !== user.confirm_password)
            return ErrorResult("Xác nhận mật khẩu không chính xác");
    }

    return {
        user,
        result: true,
        message: "",
    };
};
