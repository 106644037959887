import React from "react";
import { Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ModalComponent } from "../../components";
import { useActions } from "../../redux";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;

export const ModalCurrentTaskCollection = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    // const [modalState, setModalState] = useState(false);
    const modalState = useSelector(
        (state: any) =>
            state.manager_task_collection.modalCurrentTaskCollectionState
    );
    const setModalState = (state: any) => {
        dispatch(
            actions.ManagerTaskCollections.setModalCurrentTaskCollectionState(
                state
            )
        );
    };

    // render
    const _renderModalCurrentTaskCollection = () => {
        // current account
        let current_task_collection = useSelector(
            (state: any) =>
                state.manager_task_collection.current_task_collection
        );
        const updateCurrentTaskCollection = (entity: any) => {
            dispatch(
                actions.ManagerTaskCollections.updateCurrentTaskCollection(
                    entity
                )
            );
        };

        return (
            <div className="ModalCurrentTaskCollection">
                {current_task_collection._id && (
                    <div className="RowInfo">
                        <div className="RowTitle">_id</div>
                        <div className="RowContent">
                            {current_task_collection._id}
                        </div>
                    </div>
                )}
                <div className="RowInfo">
                    <div className="RowTitle">Tên đầu việc</div>
                    <Input
                        type="text"
                        className="RowContent"
                        value={current_task_collection.name}
                        onChange={(e) => {
                            updateCurrentTaskCollection({
                                name: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Mô tả đầu việc</div>
                    <TextArea
                        className="RowContent"
                        value={current_task_collection.description}
                        onChange={(e) => {
                            updateCurrentTaskCollection({
                                description: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <ModalComponent
            title={"Chi tiết đầu việc"}
            isModalVisible={modalState}
            onOk={() => {
                dispatch(
                    actions.ManagerTaskCollections.saveCurrentTaskCollection()
                );
            }}
            onCancel={() => {
                setModalState(false);
            }}
            cancelText="Huỷ"
            okText="Lưu"
        >
            {_renderModalCurrentTaskCollection()}
        </ModalComponent>
    );
};
