const prefix = 'manager-task-collections/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    LOAD_TASK_COLLECTIONS: prefix + 'load_task_collections',
    LOAD_TASK_COLLECTIONS_SUCCESS: prefix + 'load_task_collections_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_PAGE_SIZE: prefix + 'update_page_size',
    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',

    SEARCH_TASK_COLLECTION: prefix + 'search_task_collection',

    SET_DEFAULT_CURRENT_TASK_COLLECTION: prefix + 'set_default_current',
    SET_CURRENT_TASK_COLLECTION: prefix + 'set_current_task_collection',
    UPDATE_CURRENT_TASK_COLLECTION: prefix + 'update_current_task_collection',
    SAVE_CURRENT_TASK_COLLECTION: prefix + 'save_current_task_collection',

    REMOVE_TASK_COLLECTION: prefix + 'remove_task_collections',

    SET_MODAL_CURRENT_TASK_COLLECTION_STATE: prefix + 'set_modal_current_task_collection_state'
}

const action = {
    updateState: (state: any = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    loadTaskCollections: (params: any ={}) => {
        return {
            type: type.LOAD_TASK_COLLECTIONS,
            payload: {
                params
            }
        }
    },
    loadTaskCollectionsSuccess: (task_collections: any = []) => {
        return {
            type: type.LOAD_TASK_COLLECTIONS_SUCCESS,
            payload: {
                task_collections
            }
        }
    },

    updatePageIndex: (pageIndex: any = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updatePageSize: (pageSize: any = 10) => {
        return {
            type: type.UPDATE_PAGE_SIZE,
            payload: {
                pageSize
            }
        }
    },
    updateTextSearch: (textSearch = '') => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },

    searchTaskCollection: () => {
        return {
            type: type.SEARCH_TASK_COLLECTION,
            payload: {

            }
        }
    },

    setDefaultCurrentTaskCollection: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_TASK_COLLECTION,
            payload: {

            }
        }
    },
    setCurrentTaskCollection: (task_collection: any) => {
        return {
            type: type.SET_CURRENT_TASK_COLLECTION,
            payload: {
                task_collection
            }
        }
    },
    updateCurrentTaskCollection: (task_collection: any = { }) => {
        return {
            type: type.UPDATE_CURRENT_TASK_COLLECTION,
            payload: {
                task_collection
            }
        }
    },
    saveCurrentTaskCollection: () => {
        return {
            type: type.SAVE_CURRENT_TASK_COLLECTION,
            payload: {

            }
        }
    },
    removeTaskCollection: (id: any = '') => {
        return {
            type: type.REMOVE_TASK_COLLECTION,
            payload: {
                id
            }
        }
    },

    setModalCurrentTaskCollectionState: (state: any) => {
        return {
            type: type.SET_MODAL_CURRENT_TASK_COLLECTION_STATE,
            payload: {
                state
            }
        }
    }
}

export const ManagerTaskCollections = action

export default {
    type,
    action
}