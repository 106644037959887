const prefix = "manager-users/";

const type = {
    UPDATE_STATE: prefix + "update_state",
    SET_DEFAULT_STATE: prefix + 'set_default_state',

    LOAD_LIST_USERS: prefix + "load_list_users",
    LOAD_LIST_USERS_SUCCESS: prefix + "load_list_user_success",

    UPDATE_PAGE_SIZE: prefix + "update_page_size",
    UPDATE_PAGE_INDEX: prefix + "update_page_index",

    SET_DEFAULT_CURRENT_USER: prefix + "set_default_current_user",
    SET_CURRENT_USER: prefix + "set_current_user",
    UPDATE_CURRENT_USER: prefix + "update_current_user",
    SAVE_CURRENT_USER: prefix + "save_current_user",
    REMOVE_USER: prefix + "remove_user",

    UPDATE_TEXT_SEARCH: prefix + "update_text_search",
    SEARCH_USER: prefix + "search_user",

    SET_MODAL_CURRENT_USER_STATE: prefix + "set_modal_current_user_state",
    SET_MODAL_CHANGE_PASSWORD: prefix + "set_modal_change_password",

    CHANGE_PASSWORD_CURRENT_USER: prefix + 'change_password_current_user'
};

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state,
            },
        };
    },
    setDefaultState: () => {
        return {
            type: type.SET_DEFAULT_STATE,
            payload: {
                
            }
        }
    },

    loadListUsers: (params = {}) => {
        return {
            type: type.LOAD_LIST_USERS,
            payload: {
                params,
            },
        };
    },
    loadListUsersSuccess: (users = []) => {
        return {
            type: type.LOAD_LIST_USERS_SUCCESS,
            payload: {
                users,
            },
        };
    },

    updatePageSize: (pageSize = 10) => {
        return {
            type: type.UPDATE_PAGE_SIZE,
            payload: {
                pageSize,
            },
        };
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex,
            },
        };
    },

    setDefaultCurrentUser: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_USER,
            payload: {},
        };
    },
    setCurrentUser: (user = {}) => {
        return {
            type: type.SET_CURRENT_USER,
            payload: {
                user,
            },
        };
    },
    updateCurrentUser: (user = {}) => {
        return {
            type: type.UPDATE_CURRENT_USER,
            payload: {
                user,
            },
        };
    },
    saveCurrentUser: () => {
        return {
            type: type.SAVE_CURRENT_USER,
            payload: {},
        };
    },
    removeUser: (id = null) => {
        return {
            type: type.REMOVE_USER,
            payload: {
                id,
            },
        };
    },

    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch,
            },
        };
    },
    searchUser: () => {
        return {
            type: type.SEARCH_USER,
            payload: {},
        };
    },

    setModalCurrentUserState: (state = false) => {
        return {
            type: type.SET_MODAL_CURRENT_USER_STATE,
            payload: {
                state,
            },
        };
    },
    setModalChangePassword: (state = false) => {
        return {
            type: type.SET_MODAL_CHANGE_PASSWORD,
            payload: {
                state
            }
        }
    },

    changePasswordCurrentUser: (password = "") => {
        return {
            type: type.CHANGE_PASSWORD_CURRENT_USER,
            payload: {
                password
            }
        }
    }
};

export const ManagerUsersActions = action;

export default {
    type,
    action,
};
