import UIGlobalReducer from "./ui-global/reducer";
import AuthReducer from "./auth/reducer";
import UserReducer from "./manager-users/reducer";
import AccountReducer from './manager-fb-account/reducer'
import BotsReducer from './manager-bots/reducer'
import DashBoardReducer from './dashboard/reducer'
import TaskCollectionsManagerReducer from './manager-task-collections/reducer'
import ManagerTaskReducer from './manager-tasks/reducer'

const rootReducer = {
    ui_global: UIGlobalReducer,
    auth: AuthReducer,
    manager_users: UserReducer,
    manager_accounts: AccountReducer,
    manager_bots: BotsReducer,
    dashboard: DashBoardReducer,
    manager_task_collection: TaskCollectionsManagerReducer,
    manager_task: ManagerTaskReducer
};

export default rootReducer;
