import createApiServices from "../createApiServices";

const api = createApiServices();

const GetListTask = (
    task_collection_id = "",
    pageSize = 10,
    pageIndex = 1,
    textSearch = ""
) => {
    return api.makeAuthRequest({
        url: `manager-tasks/task-of-collection/${task_collection_id}?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${textSearch}`,
        method: "GET",
    });
};

const GetDetailTask = (id = "") => {
    return api.makeAuthRequest({
        url: `manager-tasks/${id}`,
        method: "GET",
    });
};

const InsertTask = (entity: any = {}) => {
    // let form_data = new FormData();
    // for (let key in entity) {
    //     if (entity[key] && key !== "_id") form_data.append(key, entity[key]);
    // }

    return api.makeAuthRequest({
        url: `manager-tasks`,
        method: "POST",
        data: entity,
    });
};

const UpdateTask = (id: any = "", entity: any = {}) => {
    let form_data = new FormData();
    for (let key in entity) {
        if (entity[key] && key !== "_id") form_data.append(key, entity[key]);
    }

    return api.makeAuthRequest({
        url: `manager-tasks/${id}`,
        method: "PUT",
        data: form_data,
    });
};

const RemoveTask = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-tasks/${id}`,
        method: "DELETE"
    })
}

export const Task = {
    GetListTask,
    GetDetailTask,
    InsertTask,
    UpdateTask,
    RemoveTask
};
