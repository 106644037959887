import { all, takeEvery, fork, put, select, delay } from 'redux-saga/effects';
import {
    APIServices,
    NotificationsService,
    ValidateService,
} from '../../utils';
import actions from './actions';

function* saga_LoadListTask(action: any) {
    try {
        let { params } = action.payload;

        // page Size
        let _pageSize: Promise<any> = yield select(
            (state: any) => state.manager_task.page_size,
        );
        let pageSize: any = _pageSize;

        // pageIndex
        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.manager_task.page_index,
        );
        let pageIndex: any = _pageIndex;

        // text search
        let _textSearch: Promise<any> = yield select(
            (state: any) => state.manager_task.last_search,
        );
        let textSearch: any = _textSearch;

        // task collection
        let _task_collection_id: Promise<any> = yield select(
            (state: any) => state.manager_task.task_collection_id,
        );
        let task_collection_id: any = _task_collection_id;

        if (params.task_collection_id)
            task_collection_id = params.task_collection_id;

        // params
        if (params.pageSize) pageSize = params.pageSize;
        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.textSearch) textSearch = params.textSearch;

        let _req: Promise<any> = yield APIServices.Task.GetListTask(
            task_collection_id,
            pageSize,
            pageIndex,
            textSearch,
        );
        let req: any = _req;
        let data = req.data;

        let { items, total } = data;

        yield put(
            actions.action.updateState({
                total,
            }),
        );

        if (params.task_collection_id) {
            yield put(
                actions.action.updateState({
                    task_collection_id: params.task_collection_id,
                }),
            );
        }

        yield put(actions.action.loadListTasksSuccess(items));
    } catch (ex: any) {
        console.log('[Manager task] saga_LoadListTask error ', ex.message);
    }
}

function* saga_UpdatePageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListTasks({
                pageIndex,
            }),
        );
    } catch (ex: any) {
        console.log('[Manager task] saga_UpdatePageIndex error ', ex.message);
    }
}

function* saga_UpdatePageSize(action: any) {
    try {
        let pageIndex = 1;
        let pageSize = action.payload.pageSize;

        yield put(
            actions.action.loadListTasks({
                pageIndex,
                pageSize,
            }),
        );
    } catch (ex: any) {
        console.log('[Manager task] saga_UpdatePageSize error ', ex.message);
    }
}

function* saga_SearchTask(action: any) {
    try {
        let pageIndex = 1;

        let _textSearch: Promise<any> = yield select(
            (state: any) => state.manager_task.text_search,
        );
        let textSearch: any = _textSearch;

        yield put(
            actions.action.loadListTasks({
                pageIndex,
                textSearch,
            }),
        );
    } catch (ex: any) {
        console.log('[Manager task] saga_SearchTask error ', ex.message);
    }
}

function* saga_RemoveTask(action: any) {
    try {
        let { id } = action.payload;
        yield APIServices.Task.RemoveTask(id);

        NotificationsService.success('Xoá việc thành công');

        yield put(actions.action.loadListTasks({}));
    } catch (ex: any) {
        NotificationsService.error('Xoá việc thất bại');
        console.log('[Manager task] saga_RemoveTask error ', ex.message);
    }
}

function* saga_SaveCurrentTask(action: any) {
    try {
    } catch (ex: any) {
        console.log('[Manager task] saga_SaveCurrentTask error ', ex.message);
    }
}

function* saga_LoadListLogs(action: any) {
    try {
        let { params } = action.payload;

        // page Size
        let _pageSize: Promise<any> = yield select(
            (state: any) => state.manager_task.log_page_size,
        );
        let pageSize: any = _pageSize;

        // pageIndex
        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.manager_task.log_page_index,
        );
        let pageIndex: any = _pageIndex;

        // text search
        let _textSearch: Promise<any> = yield select(
            (state: any) => state.manager_task.last_search,
        );
        let textSearch: any = _textSearch;
        textSearch = '';

        // task
        let _task_id: Promise<any> = yield select(
            (state: any) => state.manager_task.task_id,
        );
        let task_id: any = _task_id;

        // params
        if (params.pageSize) pageSize = params.pageSize;
        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.textSearch) textSearch = params.textSearch;

        if (params.task_id) {
            task_id = params.task_id;
            pageIndex = 1;
        }

        let _req: Promise<any> = yield APIServices.Log.GetListLogOfTask(
            task_id,
            pageSize,
            pageIndex,
            textSearch,
        );
        let req: any = _req;
        let data = req.data;

        let { items, total } = data;

        yield put(
            actions.action.updateState({
                log_total: total,
            }),
        );

        if (params.task_id) {
            yield put(
                actions.action.updateState({
                    task_id: params.task_id,
                    log_page_index: 1,
                }),
            );
        }

        yield put(actions.action.loadListLogsSuccess(items));
    } catch (ex: any) {
        console.log('[Manager task] saga_LoadListLogs error ', ex.message);
    }
}

function* saga_UpdateLogPageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListLogs({
                pageIndex,
            }),
        );
    } catch (ex: any) {
        console.log(
            '[Manager task] saga_UpdateLogPageIndex error ',
            ex.message,
        );
    }
}

function* saga_UpdateLogPageSize(action: any) {
    try {
        let pageSize = action.payload.pageSize;
        let pageIndex = 1;

        yield put(
            actions.action.loadListLogs({
                pageIndex,
                pageSize,
            }),
        );
    } catch (ex: any) {
        console.log('[Manager task] saga_UpdateLogPageSize error ', ex.message);
    }
}

function* saga_LoadTaskCollectionInfo(action: any) {
    try {
        let id = action.payload.id;

        let _req: Promise<any> =
            yield APIServices.TaskCollections.GetDetailTaskCollection(id);
        let req: any = _req;

        if (req.code == 200) {
            let info: any = req.data;
            yield put(actions.action.loadTaskTaskCollectionInfoSuccess(info));
        }
    } catch (ex: any) {
        console.log(
            '[Manager task] saga_LoadTaskCollectionInfo error ',
            ex.message,
        );
    }
}

function* saga_AddTask(action: any) {
    try {
        let task = action.payload.task;

        let _req: Promise<any> = yield APIServices.Task.InsertTask(task);
        let req: any = _req;

        if (req.code == 200) {
            let info: any = req.data;
            yield put(actions.action.loadListTasks());
        }
    } catch (ex: any) {
        console.log('[Manager task] saga_AddTask error ', ex.message);
    }
}

function* listen() {
    const types = actions.type;

    yield takeEvery(types.LOAD_LIST_TASK, saga_LoadListTask);
    yield takeEvery(types.UPDATE_PAGE_INDEX, saga_UpdatePageIndex);
    yield takeEvery(types.UPDATE_PAGE_SIZE, saga_UpdatePageSize);
    yield takeEvery(types.SEARCH_TASK, saga_SearchTask);

    yield takeEvery(types.REMOVE_TASK, saga_RemoveTask);
    yield takeEvery(types.SAVE_CURRENT_TASK, saga_SaveCurrentTask);

    yield takeEvery(types.LOAD_LIST_LOGS, saga_LoadListLogs);
    yield takeEvery(types.UPDATE_LOG_PAGE_INDEX, saga_UpdateLogPageIndex);
    yield takeEvery(types.UPDATE_LOG_PAGE_SIZE, saga_UpdateLogPageSize);

    yield takeEvery(
        types.LOAD_TASK_COLLECTION_INFO,
        saga_LoadTaskCollectionInfo,
    );

    yield takeEvery(types.ADD_TASK, saga_AddTask);
}

export default function* managerTaskCollectionSaga() {
    yield all([fork(listen)]);
}
