import React from "react";
import { Table } from "antd";
import { FaEdit, FaEye } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";
import { showConfirm } from "../../components";
import { CommonHelper } from "../../utils";
import { Link } from "react-router-dom";
import { RouterLinkPrefix, RouterLinks } from "../../const";

const columns = [
    {
        title: "TT",
        dataIndex: "stt",
    },
    {
        title: "Tên đầu việc",
        dataIndex: "name",
    },
    {
        title: "Mô tả",
        dataIndex: "description",
    },
    {
        title: "Ngày tạo",
        dataIndex: "created_date",
        render: (timestamp: any) => {
            // let date = new Date(timestamp)

            return (
                <div>
                    {CommonHelper.Date.convertIsoDateToDatetime(timestamp)}
                </div>
            );
        },
    },

    {
        title: "Hành động",
        width: 350,
        render: (entity: any) => {
            return (
                <div className="ListActions">
                    <Link
                        to={`${RouterLinkPrefix.MANAGER_TASK}/${entity._id}`}
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            // if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaEye className="Icon" />
                        Chi tiết
                    </Link>
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaEdit className="Icon" />
                        Sửa thông tin
                    </div>
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#ff3300",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleRemove) entity.handleRemove();
                        }}
                    >
                        <FiTrash2 className="Icon" />
                        Xoá
                    </div>
                </div>
            );
        },
    },
];

export const TableListTaskCollections = (props: any) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const loading = useSelector(
        (state: any) => state.manager_task_collection.loading
    );
    const task_collections = useSelector(
        (state: any) => state.manager_task_collection.task_collections
    );

    const setModalState = (state: any) => {
        dispatch(
            actions.ManagerTaskCollections.setModalCurrentTaskCollectionState(
                state
            )
        );
    };

    // current account
    const setCurrentTaskCollection = (entity: any) => {
        dispatch(
            actions.ManagerTaskCollections.setCurrentTaskCollection(entity)
        );
    };

    // pagination
    const pageIndex = useSelector(
        (state: any) => state.manager_task_collection.page_index
    );
    const pageSize = useSelector(
        (state: any) => state.manager_task_collection.page_size
    );

    // add handle
    let items = task_collections;
    let data = [];
    for (let index = 0; index < items.length; index++) {
        data.push(items[index]);

        data[index] = {
            ...data[index],
            ...{
                stt: pageSize * (pageIndex - 1) + index + 1,
                handleEdit: () => {
                    setCurrentTaskCollection(items[index]);
                    setModalState(true);
                },
                handleRemove: () => {
                    setCurrentTaskCollection(items[index]);
                    showConfirm({
                        title: "Bạn chắc chắn xoá đầu việc này?",
                        content: "",
                        onOk: () => {
                            dispatch(
                                actions.ManagerTaskCollections.removeTaskCollection(
                                    items[index]._id
                                )
                            );
                        },
                        onCancel: () => {},
                    });
                },
            },
        };
    }

    return (
        <Table
            columns={columns}
            rowKey={(record) => record._id}
            dataSource={data}
            pagination={false}
            loading={loading}
            style={{
                flex: 1,
            }}
        />
    );
};
