import React, { useState } from 'react';
import { Select, Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    ModalComponent,
    PaginationComponent,
    SpinComponent,
} from '../../components';
import { useActions } from '../../redux';
import { AppleOutlined, AndroidOutlined } from '@ant-design/icons';
import { CommonHelper } from '../../utils';
import { LogTypes } from '../../const';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const { Option } = Select;
const { TabPane } = Tabs;

// Validation
const schema = yup.object().shape({
    exampleRequired: yup
        .string()
        .required('Email is required!')
        .email('Email is invalid!'),
    type: yup.string().required('Type is required'),
    payload: yup.string().required('Pay load là bắt buộc '),
});

export const ModalCurrentTask = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    let logs = useSelector((state: any) => state.manager_task.logs);
    let loading = useSelector((state: any) => state.manager_task.log_loading);

    // const [modalState, setModalState] = useState(false);
    const modalState = useSelector(
        (state: any) => state.manager_task.modalCurrentTaskState,
    );
    const setModalState = (state: any) => {
        dispatch(actions.ManagerTasks.setModalCurrentTask(state));
    };

    let currentAccount = useSelector(
        (state: any) => state.manager_accounts.current_account,
    );

    let total = useSelector((state: any) => state.manager_task.log_total);
    let pageSize = useSelector(
        (state: any) => state.manager_task.log_page_size,
    );
    let pageIndex = useSelector(
        (state: any) => state.manager_task.log_page_index,
    );

    let [activeKey, setActiveKey] = useState('info');
    useEffect(() => {
        setActiveKey('info');
    }, [modalState]);

    // render
    const _renderModalCurrentTask = () => {
        const {
            register,
            handleSubmit,
            watch,
            formState: { errors },
        }: any = useForm({
            resolver: yupResolver(schema),
        });
        const onSubmit = (data: any) => console.log(data);

        // current account
        let currentTask = useSelector(
            (state: any) => state.manager_task.current_task,
        );

        let _payload =
            currentTask && currentTask.payload ? currentTask.payload : {};
        let payload = CommonHelper.String.convertJsonToObject(_payload);
        let key_payloads = Object.keys(payload);

        const updateCurrentTask = (entity: any) => {
            dispatch(actions.ManagerTasks.updateCurrentTask(entity));
        };

        return (
            <form className="InfoCurrentTab" onSubmit={handleSubmit(onSubmit)}>
                {currentTask._id && (
                    <div className="RowInfo">
                        <div className="RowTitle">Id</div>
                        <div className="RowContent">{currentTask._id}</div>
                    </div>
                )}
                <div className="RowInfo">
                    <div className="RowTitle">Loại việc</div>
                    <Select
                        className="RowContentSelect"
                        value={currentTask.type}
                        onChange={(value) => {
                            // updateCurrentAccount({
                            //     type: value,
                            // });
                        }}
                    >
                        <Option value={'comment_post'}>Bình Luận</Option>
                        <Option value={'like_post'}>Like</Option>
                        <Option value={'share_post'}>Chia sẻ</Option>
                    </Select>
                </div>

                {key_payloads.map((item, index) => {
                    return (
                        <div className="RowInfo">
                            <div className="RowTitle">{item}</div>
                            <div className="RowContent">{payload[item]}</div>
                        </div>
                    );
                })}
            </form>
        );
    };

    const _renderListLogs = () => {
        return (
            <div className="ListLogsTab">
                <div className="ListLogs">
                    {logs.map((log: any, index: any) => {
                        let type_log = CommonHelper.String.getTypeOfLog(
                            log.content,
                        );
                        let color = 'black';

                        switch (type_log) {
                            case LogTypes.SUCCESS:
                                color = '#00b33c';
                                break;

                            case LogTypes.ERROR:
                                color = '#ff6600';
                                break;

                            case LogTypes.NORMAL:
                                color = '#3399ff';
                                break;

                            default:
                                color = 'black';
                                break;
                        }

                        return (
                            <div
                                className="LogItem"
                                style={{
                                    borderLeftColor: color,
                                }}
                                key={index}
                            >
                                <div className="Title">{log.content}</div>
                                <div className="Time">
                                    {CommonHelper.Date.convertIsoDateToDatetime(
                                        log.timestamp,
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>

                <PaginationComponent
                    total={total}
                    onChange={(_page: any, _pageSize: any) => {
                        if (_pageSize !== pageSize) {
                            dispatch(
                                actions.ManagerTasks.updateLogPageSize(
                                    _pageSize,
                                ),
                            );
                            return;
                        }
                        if (_page !== pageIndex) {
                            dispatch(
                                actions.ManagerTasks.updateLogPageIndex(_page),
                            );
                        }
                    }}
                    current={pageIndex}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    simple
                />
            </div>
        );
    };

    let display: any = 'inline';
    if (currentAccount._id) display = 'none';

    return (
        <ModalComponent
            // title={"Chi tiết công việc"}
            title=""
            isModalVisible={modalState}
            onOk={() => {
                dispatch(actions.ManagerAccountsActions.saveCurrentAccount());
            }}
            onCancel={() => {
                setModalState(false);
            }}
            // footer={footer}
            cancelButtonProps={{ style: { display } }}
            okButtonProps={{ style: { display } }}
            cancelText="Huỷ"
            okText="Lưu"
            bodyStyle={{
                backgroundColor: 'white',
            }}
        >
            <Tabs
                activeKey={activeKey}
                onChange={(key) => {
                    setActiveKey(key);
                }}
                tabBarStyle={{ color: 'black', borderBottom: 'black' }}
            >
                <TabPane tab={<span>Thông tin</span>} key="info">
                    {_renderModalCurrentTask()}
                </TabPane>
                <TabPane tab={<span>Logs ({total})</span>} key="logs">
                    {/* {loading ? <SpinComponent /> : _renderListLogs()} */}
                    {loading ? (
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {<SpinComponent />}
                        </div>
                    ) : (
                        _renderListLogs()
                    )}
                </TabPane>
            </Tabs>
        </ModalComponent>
    );
};
