import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { PaginationComponent } from "../../components";
import { useActions } from "../../redux";
import { CommonHelper } from "../../utils";

export const PendingTaskDashboard = (props: any = {}) => {
    let tasks = useSelector((state: any) => state.dashboard.pending_tasks);

    let total = useSelector((state: any) => state.dashboard.pd_total);
    let pageSize = useSelector((state: any) => state.dashboard.pd_page_size);
    let pageIndex = useSelector((state: any) => state.dashboard.pd_page_index);

    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    return (
        <div className="PendingTaskDashboard">
            <div className="RowTitle">
                Công việc đang đợi thực hiện ({total})
            </div>

            <div className="ListPendingTask">
                {tasks.map((task: any, index: any) => {
                    let payload = CommonHelper.String.convertJsonToObject(
                        task.payload
                    );
                    let keys = Object.keys(payload);

                    return (
                        <div
                            className="PendingTask"
                            key={index}
                            onClick={() => {
                                dispatch(
                                    actions.DashBoardActions.setModalDetailTaskState(
                                        true
                                    )
                                );
                                dispatch(
                                    actions.DashBoardActions.loadListLogs({
                                        task_id: task._id,
                                    })
                                );
                                dispatch(
                                    actions.DashBoardActions.setCurrentTask(
                                        task
                                    )
                                );
                            }}
                        >
                            <div className="Row">{task.type}</div>
                            <div className="Payload">
                                {keys.map((key, index) => {
                                    return (
                                        <div key={index}>
                                            <span
                                                style={{ fontWeight: 600 }}
                                            >{`${key}: `}</span>
                                            {payload[key]}
                                        </div>
                                    );
                                })}
                            </div>

                            <div className="DateCreated">
                                {CommonHelper.Date.convertIsoDateToDatetime(
                                    task.created_date
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>

            <div style={{ marginTop: 10 }}>
                {/* <PaginationComponent
                    total={total}
                    onChange={(_page: any, _pageSize: any) => {
                        // if (_pageSize !== pageSize) {
                        //     dispatch(
                        //         actions.ManagerAccountsActions.updatePageSize(_pageSize)
                        //     );
                        //     return;
                        // }
                        // if (_page !== pageIndex) {
                        //     dispatch(
                        //         actions.ManagerAccountsActions.updatePageIndex(_page)
                        //     );
                        // }
                    }}
                    // current={pageIndex}
                    pageSize={pageSize}
                    showSizeChanger={true}
                    simple
                /> */}
            </div>
        </div>
    );
};
