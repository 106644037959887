import React from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";

export const TopBarControl = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const setModalCurrentBotState = (state: any) => {
        dispatch(actions.ManagerBotsActions.setModalCurrentBotState(state));
    };

    // list account
    const textSearch = useSelector(
        (state: any) => state.manager_bots.textSearch
    );

    return (
        <div className="MUP_ActionControl">
            <div
                className="MUP_AddButton"
                style={{
                    backgroundColor: "#3399ff",
                    color: "white",
                }}
                onClick={() => {
                    dispatch(
                        actions.ManagerBotsActions.setDefaultCurrentBot()
                    );
                    setModalCurrentBotState(true);
                }}
            >
                <AiOutlineUserAdd className="Icon" />
                Thêm tài khoản bot
            </div>
            <div className="MUP_SearchBox">
                <input
                    type="text"
                    value={textSearch}
                    onChange={(e) => {
                        dispatch(
                            actions.ManagerBotsActions.updateTextSearch(
                                e.target.value
                            )
                        );
                    }}
                    placeholder="Nhập để tìm kiếm"
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            dispatch(actions.ManagerBotsActions.searchBot());
                        }
                    }}
                />
                <FiSearch
                    className="MUPS_Icon"
                    onClick={() => {
                        dispatch(actions.ManagerBotsActions.searchBot());
                    }}
                />
            </div>
        </div>
    );
};
