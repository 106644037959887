import React, { useEffect } from "react";
import { LoginPage } from "./pages";

import { Provider } from "react-redux";
import { store } from "./redux/store";
import {
    Route,
    Switch,
    BrowserRouter as Router,
    Redirect,
} from "react-router-dom";
import { RouterLinks } from "./const";
import { LoginRequireComponent } from "./components";
import { AdminRouter } from "./router";

import ReactNotification from "react-notifications-component";

import "./app.scss";
import "antd/dist/antd.css";

import "react-notifications-component/dist/theme.css";

function App() {
    useEffect(() => {
        // NotificationsService.success("abc")
    }, []);

    return (
        <>
            <ReactNotification />
            <Provider store={store}>
                <Router>
                    <Switch>
                        <Route
                            component={LoginPage}
                            path={RouterLinks.LOGIN_PAGE}
                        />

                        <LoginRequireComponent
                            component={AdminRouter}
                            path={RouterLinks.APP}
                        />

                        <Redirect to={RouterLinks.HOME_PAGE} />
                    </Switch>
                </Router>
            </Provider>
        </>
    );
}

export default App;
