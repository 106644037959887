import React from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";

export const TopBarControl = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const setModalCurrentTaskCollectionState = (state: any) => {
        dispatch(
            actions.ManagerTaskCollections.setModalCurrentTaskCollectionState(
                state
            )
        );
    };

    // list account
    const textSearch = useSelector(
        (state: any) => state.manager_task_collection.textSearch
    );

    return (
        <div className="MUP_ActionControl">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <div
                    className="MUP_AddButton"
                    style={{
                        backgroundColor: "#3399ff",
                        color: "white",
                        marginRight: 5,
                    }}
                    onClick={() => {
                        dispatch(
                            actions.ManagerTaskCollections.setDefaultCurrentTaskCollection()
                        );
                        setModalCurrentTaskCollectionState(true);
                    }}
                >
                    <AiOutlineUserAdd className="Icon" />
                    Thêm đầu việc
                </div>
            </div>
            <div className="MUP_SearchBox">
                <input
                    type="text"
                    value={textSearch}
                    onChange={(e) => {
                        dispatch(
                            actions.ManagerTaskCollections.updateTextSearch(
                                e.target.value
                            )
                        );
                    }}
                    placeholder="Nhập để tìm kiếm"
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            dispatch(
                                actions.ManagerTaskCollections.searchTaskCollection()
                            );
                        }
                    }}
                />
                <FiSearch
                    className="MUPS_Icon"
                    onClick={() => {
                        dispatch(
                            actions.ManagerTaskCollections.searchTaskCollection()
                        );
                    }}
                />
            </div>
        </div>
    );
};
