import createApiServices from "../createApiServices";

const api = createApiServices();

const GetListTaskCollections = (
    pageSize: any = 10,
    pageIndex: any = 1,
    keyword: any = ""
) => {
    return api.makeAuthRequest({
        url: `manager-task-collections?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${keyword}`,
        method: "GET",
    });
};

const GetDetailTaskCollection = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-task-collections/${id}`,
        method: "GET",
    });
};

const InsertTaskCollection = (entity: any) => {
    let form_data = new FormData();
    for (let key in entity) {
        if (entity[key] && key !== "_id") form_data.append(key, entity[key]);
    }

    return api.makeAuthRequest({
        url: "manager-task-collections",
        method: "POST",
        data: form_data,
    });
};

const UpdateTaskCollection = (id: any, entity: any) => {
    let form_data = new FormData();
    for (let key in entity) {
        if (entity[key] && key !== "_id") form_data.append(key, entity[key]);
    }

    return api.makeAuthRequest({
        url: `manager-task-collections/${id}`,
        method: "PUT",
        data: form_data,
    });
};

const RemoveTaskCollection = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-task-collections/${id}`,
        method: "DELETE",
    });
};

export const TaskCollections = {
    GetListTaskCollections,
    GetDetailTaskCollection,
    InsertTaskCollection,
    UpdateTaskCollection,
    RemoveTaskCollection,
};
