const prefix = 'dashboard/'

const type ={
    UPDATE_STATE: prefix + 'update_state',

    RELOAD: prefix + 'reload',
    
    LOAD_LIST_BOT: prefix + 'load_list_bot',
    LOAD_LIST_BOT_SUCCESS: prefix + 'load_list_bot_success',

    LOAD_LIST_PENDING_TASK: prefix + 'load_list_pending_task',
    LOAD_LIST_PENDING_TASK_SUCCESS: prefix + 'load_list_pending_task_success',

    LOAD_COMPLETED_TASK: prefix + 'load_compeleted_task',
    LOAD_COMPLETED_TASK_SUCCESS: prefix + 'load_list_completed_task_success',

    PENDING_TASK_UPDATE_PAGE_INDEX: prefix + 'pending_task_update_page_size', 
    
    COMPLETED_TASK_UPDATE_PAGE_SIZE: prefix + 'completed_task',
    COMPLETED_TASK_UPDATE_PAGE_INDEX: prefix + 'completed_task_update_page_index',

    SET_MODAL_DETAIL_TASK_STATE: prefix + 'set_modal_detail_task_state',
    SET_CURRENT_TASK: prefix + 'set_current_task',

    LOAD_LIST_LOGS: prefix + 'load_list_logs',
    LOAD_LIST_LOGS_SUCCESS: prefix + 'load_lisk_logs_success',
    UPDATE_LOG_PAGE_INDEX: prefix + 'update_log_page_index',
    UPDATE_LOG_PAGE_SIZE: prefix + 'update_log_page_size',
}

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    reload: () => {
        return {
            type: type.RELOAD,
            payload: {

            }
        }
    },

    loadListBot: () => {
        return {
            type: type.LOAD_LIST_BOT,
            payload: {
            }
        }
    },
    loadListBotSuccess: (bots = []) => {
        return {
            type: type.LOAD_LIST_BOT_SUCCESS,
            payload: {
                bots
            }
        }
    },

    loadListPendingTask: (params: any = {}) => {
        return {
            type: type.LOAD_LIST_PENDING_TASK,
            payload: {
                params
            }
        }
    },
    loadListPendingTaskSuccess: (pending_tasks = []) => {
        return {
            type: type.LOAD_LIST_PENDING_TASK_SUCCESS,
            payload: {
                pending_tasks
            }
        }
    },

    loadCompletedTask: (params: any = {}) => {
        return {
            type: type.LOAD_COMPLETED_TASK,
            payload: {
                params
            }
        }
    },
    loadCompletedTaskSuccess: (completed_tasks = []) => {
        return {
            type: type.LOAD_COMPLETED_TASK_SUCCESS,
            payload: {
                completed_tasks
            }
        }
    },

    pendingTaskUpdateIndex: (pageIndex = 1) => {
        return {
            type: type.PENDING_TASK_UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },

    completedTaskUpdatePageSize: (pageSize = 10) => {
        return {
            type: type.COMPLETED_TASK_UPDATE_PAGE_SIZE,
            payload: {
                pageSize
            }
        }
    },
    completedTaskUpdatePageIndex: (pageIndex = 1) => {
        return {
            type: type.COMPLETED_TASK_UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },

    setModalDetailTaskState: (state: any) => {
        return {
            type: type.SET_MODAL_DETAIL_TASK_STATE,
            payload: {
                state
            }
        }
    },
    setCurrentTask: (task: any) => {
        return {
            type: type.SET_CURRENT_TASK,
            payload: {
                task
            }
        }
    },


    loadListLogs: (params = {}) => {
        return {
            type: type.LOAD_LIST_LOGS,
            payload: {
                params
            }
        }
    },
    loadListLogsSuccess: (logs = []) => {
        return {
            type: type.LOAD_LIST_LOGS_SUCCESS,
            payload: {
                logs
            }
        }
    },
    updateLogPageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_LOG_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updateLogPageSize: (pageSize = 10) => {
        return {
            type: type.UPDATE_LOG_PAGE_SIZE,
            payload: {
                pageSize
            }
        }
    },
}

export const DashBoardActions = action 

export default {
    type, 
    action
}