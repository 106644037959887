import React, { useEffect, useState } from "react";
import "./index.scss";

// context
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";
import { Redirect, useLocation } from "react-router-dom";
import { RouterLinks } from "../../const";

import * as Resources from "../../resource";

export const LoginPage = (props: any) => {
    // const { t } = useLanguage();

    const [username, setUserName] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const actions = useActions();

    const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

    useEffect(() => {});

    const location = useLocation();

    let nextPage = RouterLinks.HOME_PAGE;
    try {
        nextPage = location.state.from;
    } catch {}

    if (isLoggedIn) {
        if (nextPage !== RouterLinks.LOGIN_PAGE)
            return <Redirect to={nextPage} />;
        else return <Redirect to={RouterLinks.HOME_PAGE} />;
    }

    // handle
    const _handleLogin = () => {
        dispatch(actions.AuthActions.login(username, password));
    };

    return (
        <div className="LoginPage">
            <div className="LGP_LoginPanel">
                <div className="LGP_RowInfo" style={{ marginBottom: 0 }}>
                    <img
                        alt=""
                        src={Resources.Images.Logo}
                        className="LGP_LogoImage"
                    />
                    <div className="LGP_Title">BOT MANAGER</div>
                </div>

                <div className="LGP_RowInfo">
                    <div className="LGP_RowInfoTitle">Tên đăng nhập</div>
                    <div className="LGP_RowInfoContent">
                        <input
                            value={username}
                            onChange={(e) => {
                                setUserName(e.target.value);
                            }}
                            type="text"
                            className="LGP_RowInfoTextBox"
                            onKeyPress={(e) => {
                                if (e.key === "Enter") _handleLogin();
                            }}
                        />
                    </div>
                </div>

                <div className="LGP_RowInfo">
                    <div className="LGP_RowInfoTitle">Mật khẩu</div>
                    <div className="LGP_RowInfoContent">
                        <input
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            type="password"
                            className="LGP_RowInfoTextBox"
                            onKeyPress={(e) => {
                                if (e.key === "Enter") _handleLogin();
                            }}
                        />
                    </div>
                </div>

                <div
                    className="LGP_ControlPanel"
                    onClick={() => {
                        _handleLogin();
                    }}
                >
                    <div className="LGP_Button">Đăng nhập</div>
                </div>
            </div>
        </div>
    );
};
