import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// const
// import { RouterLinks } from '../../../const';

// lib
import { Popover } from "antd";
import { UnorderedListOutlined } from "@ant-design/icons";

import "./topbar.scss";

// icon
import { FaLock, FaSignOutAlt } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md"
import { useActions } from "../../redux";
import { APIServices } from "../../utils";

// subcomponent
import ModalChangePassword from "./modal_change_password";
import { ModalUserInfo } from "./modal_user_info";

export const TopBar = () => {
    // local
    const [modalChangePasswordState, setModalChangePasswordState] =
        useState(false);

    const [modaUserInfoState, setModalUserInfoState] = useState(false);

    // redux
    const userInfo = useSelector((state: any) => state.auth.userInfo);

    const dispatch = useDispatch();
    const actions = useActions();

    const collapse = useSelector((state: any) => state.ui_global.collapse);

    userInfo.avatar = APIServices.Images.getImageUrl(userInfo.avatar);

    /// handle
    const _handleLogout = () => {
        dispatch(actions.AuthActions.logout());
    };

    const _handleUpdateCollapse = () => {
        dispatch(actions.UIGlobalActions.SetCollapse(!collapse));
    };

    // render
    const renderUserInfo = () => {
        return (
            <div className="TB_InfoPopOver">
                <div className="AvatarPanel">
                    <img
                        className="TB_InfoAvatarInPanel"
                        src={userInfo.avatar}
                        alt="null"
                    />
                    <div className="TB_InfoFullName">{userInfo.fullname}</div>
                </div>
                <div className="MenuPanel">
                    <div
                        className="TB_MP_RowInfo"
                        onClick={() => {
                            setModalUserInfoState(true);
                        }}
                    >
                        <MdInfoOutline className="TB_MP_Icon" />
                        Cập nhật thông tin cá nhân
                    </div>
                    <div
                        className="TB_MP_RowInfo"
                        onClick={() => {
                            setModalChangePasswordState(true);
                        }}
                    >
                        <FaLock className="TB_MP_Icon" />
                        Đổi mật khẩu
                    </div>
                    <div
                        className="TB_MP_RowInfo"
                        onClick={() => {
                            _handleLogout();
                        }}
                    >
                        <FaSignOutAlt className="TB_MP_Icon" />
                        Đăng xuất
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="TopBar">
            <div className="TB_SettingCollapse">
                <div
                    className="TB_IconControl"
                    onClick={() => {
                        _handleUpdateCollapse();
                    }}
                >
                    <UnorderedListOutlined />
                </div>
            </div>
            <div className="TB_UserInfo">
                <Popover
                    placement="bottomRight"
                    content={renderUserInfo}
                    trigger="click"
                >
                    <img
                        className="TB_InfoAvatar"
                        src={userInfo.avatar}
                        alt="null"
                    />
                </Popover>
            </div>
            <ModalChangePassword
                visible={modalChangePasswordState}
                setVisible={setModalChangePasswordState}
                onOk={(old_password: any, password: any) => {
                    dispatch(
                        actions.AuthActions.changePassword(
                            old_password,
                            password
                        )
                    );
                }}
            />
            <ModalUserInfo
                visible={modaUserInfoState}
                setVisible={setModalUserInfoState}
                onOk={() => {
                    alert("update");
                }}
            />
        </div>
    );
};
