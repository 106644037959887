import React from "react";
import { Table } from "antd";
import { FaEye, FaRegEdit, FaUserSecret } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";
import { showConfirm } from "../../components";
import { stat } from "fs";
import { BotAllowWorkingEnum } from "../../const";

const columns = [
    {
        title: "TT",
        dataIndex: "stt",
    },
    {
        title: "Tên bot",
        dataIndex: "name",
    },
    {
        title: "Tên đăng nhập",
        dataIndex: "username",
    },
    {
        title: "Trạng thái",
        dataIndex: "allow_working",
        render: (status: any) => {
            let color = "green";
            if (status == BotAllowWorkingEnum.DENY) color = "red";

            return <div style={{ color, fontWeight: 550 }}>{status}</div>;
        },
    },
    {
        title: "Hành động",
        width: 220,
        render: (entity: any) => {
            return (
                <div className="ListActions">
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaRegEdit className="Icon" />
                        Sửa
                    </div>
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#ff3300",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleRemove) entity.handleRemove();
                        }}
                    >
                        <FiTrash2 className="Icon" />
                        Xoá
                    </div>
                </div>
            );
        },
    },
];

export const TableListBots = (props: any) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const loading = useSelector((state: any) => state.manager_bots.loading);
    const list_bots = useSelector((state: any) => state.manager_bots.list_bots);

    const setModalState = (state: any) => {
        dispatch(actions.ManagerBotsActions.setModalCurrentBotState(state));
    };

    // current account
    const setCurrentBot = (account: any) => {
        dispatch(actions.ManagerBotsActions.setCurrentBot(account));
    };

    // pagination
    const pageIndex = useSelector((state: any) => state.manager_bots.pageIndex);
    const pageSize = useSelector((state: any) => state.manager_bots.pageSize);

    // add handle
    let items = list_bots;
    let data = [];
    for (let index = 0; index < items.length; index++) {
        data.push(items[index]);

        data[index] = {
            ...data[index],
            ...{
                stt: pageSize * (pageIndex - 1) + index + 1,
                handleEdit: () => {
                    setCurrentBot(list_bots[index]);
                    setModalState(true);
                },
                handleRemove: () => {
                    setCurrentBot(list_bots[index]);
                    showConfirm({
                        title: "Bạn chắc chắn xoá bot này?",
                        content: "",
                        onOk: () => {
                            dispatch(
                                actions.ManagerBotsActions.removeBot(
                                    list_bots[index]._id
                                )
                            );
                        },
                        onCancel: () => {},
                    });
                },
            },
        };
    }

    return (
        <Table
            columns={columns}
            rowKey={(record) => record._id}
            dataSource={data}
            pagination={false}
            loading={loading}
            style={{
                flex: 1,
            }}
        />
    );
};
