import createApiServices from "../createApiServices";

const api = createApiServices();

const LoadListBotConnected = () => {
    return api.makeAuthRequest({
        url: `core-info/list-bot-connected`,
        method: "GET",
    });
};

const LoadListPendingTask = (pageSize = 10, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `core-info/list-pending-task?pageSize=${pageSize}&pageIndex=${pageIndex}`,
        method: "GET",
    });
};

const LoadListCompletedTask = (pageSize = 10, pageIndex = 1) => {
    return api.makeAuthRequest({
        url: `manager-tasks?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=completed`,
        method: "GET",
    });
};

export const Monitor = {
    LoadListBotConnected,
    LoadListPendingTask,
    LoadListCompletedTask,
};
