import actions from "./actions";

const default_account: any = {
    _id: null,
    UID: "",
    USERNAME: "",
    PASSWORD: "",
    SECRET: "",
    COOKIES: "",
    ISP: "",
    status: "LIVE",
};

const initState = {
    list_accounts: [],
    loading: false,

    pageSize: 6,
    pageIndex: 1,
    total: 0,

    textSearch: "",
    lastSearch: "",

    current_account: default_account,

    modal_current_account_state: false
};

const reducer = (state = initState, action: any) => {
    console.log("action : ", action)

    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...action.payload.state,
            };

        case actions.type.SET_DEFAULT_STATE:
            return initState;

        case actions.type.LOAD_LIST_ACCOUNTS:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };

        case actions.type.LOAD_LIST_ACCOUNTS_SUCCESS:
            return {
                ...state,
                ...{
                    list_accounts: action.payload.accounts,
                    loading: false,
                },
            };

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: action.payload.pageIndex,
                },
            };

        case actions.type.UPDATE_PAGE_SIZE:
            return {
                ...state,
                ...{
                    pageIndex: 1,
                    pageSize: action.payload.pageSize,
                },
            };

        case actions.type.SET_DEFAULT_CURRENT_ACCOUNT:
            return {
                ...state,
                ...{
                    current_account: default_account,
                },
            };

        case actions.type.SET_CURRENT_ACCOUNT:
            return {
                ...state,
                ...{
                    current_account: {
                        ...default_account,
                        ...action.payload.account
                    }
                },
            };

        case actions.type.UPDATE_CURRENT_ACCOUNT:
            return {
                ...state,
                ...{
                    current_account: {
                        ...state.current_account,
                        ...action.payload.account,
                    },
                },
            };

        case actions.type.SAVE_CURRENT_ACCOUNT:
            return state;

        case actions.type.REMOVE_ACCOUNT:
            return state;

        case actions.type.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: action.payload.textSearch,
                },
            };

        case actions.type.SEARCH_ACCOUNT:
            return {
                ...state,
                ...{
                    lastSearch: state.textSearch,
                },
            };

        case actions.type.SET_MODAL_CURRENT_ACCOUNT_STATE:
            return {
                ...state,
                ...{
                    modal_current_account_state: action.payload.state,
                },
            };

        case actions.type.RESET_STATUS_ALL_ACCOUNT:
            return state

        default:
            return state;
    }
};

export default reducer;
