import React, { useState } from "react";
import { ModalComponent } from "..";
import { NotificationsService } from "../../utils";

const ModalChangePassword = (props: any) => {
    const { visible, setVisible } = props

    const [_password, _setPassword] = useState('')
    const [_confirm_password, _setConfirmPassword] = useState('')
    const [_old_password, _setOldPassword] = useState('')

    const _renderModelChangePassword = () => {
        return (
            <div className="ListUser_ModalChangePassword">
                <div className="RowInfo">
                    <div className="RowTitle">Mật khẩu cũ</div>
                    <input
                        type="password"
                        className="RowContent"
                        value={_old_password}
                        onChange={(e) => {
                            _setOldPassword(e.target.value);
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Mật khẩu mới</div>
                    <input
                        type="password"
                        className="RowContent"
                        value={_password}
                        onChange={(e) => {
                            _setPassword(e.target.value);
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Xác nhận mật khẩu mới</div>
                    <input
                        type="password"
                        className="RowContent"
                        value={_confirm_password}
                        onChange={(e) => {
                            _setConfirmPassword(e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    const onChangePassword = () => {
        if (props.onOk)
            props.onOk(_old_password, _password)
    }

    const setDefaultState = () => {
        _setPassword('')
        _setConfirmPassword('')
        _setOldPassword('')
    }

    return (
        <ModalComponent
            title={"Thay đổi mật khẩu"}
            isModalVisible={visible}
            onOk={() => {
                if (_password === "") {
                    NotificationsService.error("Mật khẩu không được để trống");
                    return;
                }
                if (_password !== _confirm_password) {
                    NotificationsService.error(
                        "Xác nhận mật khẩu không chính xác"
                    );
                    return;
                }

                setVisible(false);
                onChangePassword()

                setDefaultState()
            }}
            onCancel={() => {
                setVisible(false);
                setDefaultState()
            }}
            cancelText="Huỷ"
            okText="Lưu"
            width={360}
        >
            {_renderModelChangePassword()}
        </ModalComponent>
    );
};

export default ModalChangePassword;
