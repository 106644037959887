import { LogTypes } from "../../const";

const isNumber = (a: string) => {
    if (a >= "0" && a <= "9") return true;
};

export const GetIpAddressFromString = (str = "") => {
    let ans = "";

    let cur = 0;
    while (cur < str.length && !isNumber(str[cur])) {
        cur++;
    }

    for (let index = cur; index < str.length; index++)
        if (isNumber(str[index]) || str[index] == ".") ans = ans + str[index];

    return ans;
};

export const convertJsonToObject = (str: any = "") => {
    for (let index = 0; index < str.length; index++) {
        try {
            str = JSON.parse(str);
        } catch {}
    }

    return str;
};

export const getTypeOfLog = (content = "") => {
    content = content.toUpperCase();

    if (content.includes("THÀNH CÔNG") || content.includes("SUCCESS"))
        return LogTypes.SUCCESS;

    if (content.includes("THẤT BẠI") || content.includes("FAIL"))
        return LogTypes.ERROR;

    return LogTypes.NORMAL;
};
