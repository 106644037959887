import { all, takeEvery, fork, put, select, delay } from "redux-saga/effects";
import { APIServices } from "../../utils";
import actions from "./actions";

function* saga_Reload(action: any) {
    try {
        yield put(actions.action.loadListBot());
        yield put(actions.action.loadCompletedTask());
        yield put(actions.action.loadListPendingTask());

        yield delay(15000);
        yield put(actions.action.reload());
    } catch (ex: any) {
        console.log("[Dashboard Saga] saga_Reload error : ", ex.message);
    }
}

function* saga_LoadListBots(action: any) {
    try {
        let _req: Promise<any> =
            yield APIServices.Monitor.LoadListBotConnected();
        let req: any = _req;

        let bots = req.data;

        yield put(actions.action.loadListBotSuccess(bots));
    } catch (ex: any) {
        console.log("[Dashboard Saga] saga_LoadListBots error : ", ex.message);
    }
}

function* saga_LoadListPendingTasks(action: any) {
    try {
        let { params } = action.payload;

        let _pageSize: Promise<any> = yield select(
            (state: any) => state.dashboard.pd_page_size
        );
        let pageSize: any = _pageSize;

        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.dashboard.pd_page_index
        );
        let pageIndex: any = _pageIndex;

        // params
        if (params.pageSize) pageSize = params.pageSize;
        if (params.pageIndex) pageIndex = params.pageIndex;

        let _req: Promise<any> = yield APIServices.Monitor.LoadListPendingTask(
            pageSize,
            pageIndex
        );
        let req: any = _req;

        let tasks = req.data;
        let total = tasks.length;

        yield put(
            actions.action.updateState({
                pd_total: total,
            })
        );
        yield put(actions.action.loadListPendingTaskSuccess(tasks));
    } catch (ex: any) {
        console.log(
            "[Dashboard Saga] saga_LoadListPendingTasks error : ",
            ex.message
        );
    }
}

function* saga_LoadCompetedTasks(action: any) {
    try {
        let { params } = action.payload;
        let _pageSize: Promise<any> = yield select(
            (state: any) => state.dashboard.cp_page_size
        );
        let pageSize: any = _pageSize;

        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.dashboard.cp_page_index
        );
        let pageIndex: any = _pageIndex;

        // params
        if (params.pageSize) pageSize = params.pageSize;
        if (params.pageIndex) pageIndex = params.pageIndex;

        let _req: Promise<any> =
            yield APIServices.Monitor.LoadListCompletedTask(
                pageSize,
                pageIndex
            );
        let req: any = _req;

        let tasks = req.data.items;
        let total = req.data.total;

        yield put(
            actions.action.updateState({
                cp_total: total,
            })
        );
        yield put(actions.action.loadCompletedTaskSuccess(tasks));
    } catch (ex: any) {
        console.log(
            "[Dashboard Saga] saga_LoadCompetedTasks error : ",
            ex.message
        );
    }
}

function* saga_PendingTaskUpdatePageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListPendingTask({
                pageIndex,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Dashboard Saga] saga_PendingTaskUpdatePageIndex error : ",
            ex.message
        );
    }
}

function* saga_CompletedUpdatePageSize(action: any) {
    try {
        let pageIndex = 1;
        let pageSize = action.payload.pageSize;

        yield put(
            actions.action.loadCompletedTask({
                pageIndex,
                pageSize,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Dashboard Saga] saga_CompletedUpdatePageSize error : ",
            ex.message
        );
    }
}

function* saga_CompletedUpdatePageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadCompletedTask({
                pageIndex,
            })
        );
    } catch (ex: any) {
        console.log(
            "[Dashboard Saga] saga_CompletedUpdatePageIndex error : ",
            ex.message
        );
    }
}

function* saga_LoadListLogs(action: any) {
    try {
        let { params } = action.payload;

        // page Size
        let _pageSize: Promise<any> = yield select(
            (state: any) => state.dashboard.log_page_size
        );
        let pageSize: any = _pageSize;

        // pageIndex
        let _pageIndex: Promise<any> = yield select(
            (state: any) => state.dashboard.log_page_index
        );
        let pageIndex: any = _pageIndex;

        // text search
        let _textSearch: Promise<any> = yield select(
            (state: any) => state.dashboard.last_search
        );
        let textSearch: any = _textSearch;
        textSearch = "";

        // task
        let _task_id: Promise<any> = yield select(
            (state: any) => state.dashboard.task_id
        );
        let task_id: any = _task_id;

        // params
        if (params.pageSize) pageSize = params.pageSize;
        if (params.pageIndex) pageIndex = params.pageIndex;
        if (params.textSearch) textSearch = params.textSearch;

        if (params.task_id) {
            task_id = params.task_id;
            pageIndex = 1;
        }

        let _req: Promise<any> = yield APIServices.Log.GetListLogOfTask(
            task_id,
            pageSize,
            pageIndex,
            textSearch
        );
        let req: any = _req;
        let data = req.data;

        let { items, total } = data;

        yield put(
            actions.action.updateState({
                log_total: total,
            })
        );

        if (params.task_id) {
            yield put(
                actions.action.updateState({
                    task_id: params.task_id,
                    log_page_index: 1,
                })
            );
        }

        yield put(actions.action.loadListLogsSuccess(items));
    } catch (ex: any) {
        console.log("[Dashboard] saga_LoadListLogs error ", ex.message);
    }
}

function* saga_UpdateLogPageIndex(action: any) {
    try {
        let pageIndex = action.payload.pageIndex;

        yield put(
            actions.action.loadListLogs({
                pageIndex,
            })
        );
    } catch (ex: any) {
        console.log("[Dashboard] saga_UpdateLogPageIndex error ", ex.message);
    }
}

function* saga_UpdateLogPageSize(action: any) {
    try {
        let pageSize = action.payload.pageSize;
        let pageIndex = 1;

        yield put(
            actions.action.loadListLogs({
                pageIndex,
                pageSize,
            })
        );
    } catch (ex: any) {
        console.log("[Dashboard] saga_UpdateLogPageSize error ", ex.message);
    }
}

function* listen() {
    const types = actions.type;

    yield takeEvery(actions.type.RELOAD, saga_Reload);
    yield takeEvery(actions.type.LOAD_LIST_BOT, saga_LoadListBots);
    yield takeEvery(
        actions.type.LOAD_LIST_PENDING_TASK,
        saga_LoadListPendingTasks
    );
    yield takeEvery(actions.type.LOAD_COMPLETED_TASK, saga_LoadCompetedTasks);

    yield takeEvery(
        actions.type.PENDING_TASK_UPDATE_PAGE_INDEX,
        saga_PendingTaskUpdatePageIndex
    );

    yield takeEvery(
        actions.type.COMPLETED_TASK_UPDATE_PAGE_SIZE,
        saga_CompletedUpdatePageSize
    );
    yield takeEvery(
        actions.type.COMPLETED_TASK_UPDATE_PAGE_INDEX,
        saga_CompletedUpdatePageIndex
    );

    yield takeEvery(types.LOAD_LIST_LOGS, saga_LoadListLogs);
    yield takeEvery(types.UPDATE_LOG_PAGE_INDEX, saga_UpdateLogPageIndex);
    yield takeEvery(types.UPDATE_LOG_PAGE_SIZE, saga_UpdateLogPageSize);
}

export default function* authSaga() {
    yield all([fork(listen)]);
}
