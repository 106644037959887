import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useActions } from '../../redux';

// scss
import './index.scss';
import { ModalCurrentTask } from './modal-current-task';
import { ModalAddTask } from './modal-add-task';
import { Pagination } from './pagination';
import { TableListTasks } from './table-list-tasks';
import { TopBarControl } from './top-bar-control';

export const ManagerTaskPage = () => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    let params: any = useParams();
    let { task_collection_id } = params;

    const [modalState, setModalState] = useState(false);

    // load
    useEffect(() => {
        dispatch(actions.ManagerTasks.loadListTasks({ task_collection_id }));
        dispatch(actions.ManagerTasks.loadTaskCollectionInfo(task_collection_id))
    }, []);

    return (
        <div className="ManagerTaskPage">
            <TopBarControl
                setAddModalVisible={(state: any) => {
                    setModalState(state);
                }}
            />
            <TableListTasks />
            <Pagination />
            <ModalCurrentTask />
            <ModalAddTask
                visible={modalState}
                setVisible={(state: any) => {
                    setModalState(state);
                }}
                onCancel={() => {
                    setModalState(false);
                }}
                onOk={() => {
                    setModalState(false)
                }}
            />
        </div>
    );
};
