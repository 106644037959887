import { Col, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpinComponent } from "../../components";
import { useActions } from "../../redux";
import { CommonHelper } from "../../utils";

export const CurrentWorkDashboard = (props: any = {}) => {
    // redux
    let listbots = useSelector((state: any) => state.dashboard.bots);
    const dispatch = useDispatch();
    const actions = useActions();

    return (
        <div className="CurrentWorkDashboard">
            <div className="Title">Công việc hiện tại</div>
            <Row style={{ display: "flex" }} className="ListBot">
                {listbots.map((bot: any, index: any) => {
                    let status = bot.status;

                    let color = "green";
                    if (status !== "FREE") color = "Orange";

                    let empty: any = {};
                    let task = bot.current_task ? bot.current_task : empty;
                    let payload = CommonHelper.String.convertJsonToObject(
                        task.payload
                    );
                    let keys = Object.keys(payload);

                    let iconLoading =
                        status !== "FREE" ? <SpinComponent /> : null;

                    return (
                        <Col
                            xs={24}
                            sm={24}
                            md={12}
                            lg={11}
                            xl={10}
                            key={index}
                            style={{
                                display: "flex",
                            }}
                        >
                            <div
                                className="BotItem"
                                style={{ borderLeftColor: color }}
                            >
                                <div className="BotStatus" style={{ color }}>
                                    {bot.status + " "} {iconLoading}
                                </div>

                                <div className="BotName">{bot.info.name}</div>
                                <div className="BotInfo">
                                    {CommonHelper.String.GetIpAddressFromString(
                                        bot.info.ip_address
                                    )}
                                </div>

                                <div className="TaskTitle">
                                    Việc đang thực hiện
                                </div>
                                <div className="CurrentTask">
                                    {bot.current_task && (
                                        <div
                                            onClick={() => {
                                                let task = bot.current_task;

                                                dispatch(
                                                    actions.DashBoardActions.setModalDetailTaskState(
                                                        true
                                                    )
                                                );
                                                dispatch(
                                                    actions.DashBoardActions.loadListLogs(
                                                        { task_id: task._id }
                                                    )
                                                );
                                                dispatch(
                                                    actions.DashBoardActions.setCurrentTask(
                                                        task
                                                    )
                                                );
                                            }}
                                        >
                                            <div className="Type">
                                                {task.type}
                                            </div>
                                            <div className="Payload">
                                                {keys.map((key, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <span
                                                                style={{
                                                                    fontWeight: 600,
                                                                }}
                                                            >{`${key}: `}</span>
                                                            {payload[key]}
                                                        </div>
                                                    );
                                                })}
                                            </div>

                                            <div className="DateCreated">
                                                {CommonHelper.Date.convertIsoDateToDatetime(
                                                    task.created_date
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Col>
                    );
                })}
            </Row>
        </div>
    );
};
