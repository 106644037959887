import React from "react";
import { Pagination } from "antd";

import './index.scss'

export const PaginationComponent = (props: any) => {
    return (
        <Pagination
            showQuickJumper
            pageSizeOptions={["1","2", "5", "10", "20", "30", "50"]}
            {...props}
        />
    );
};
