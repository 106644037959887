import Layout, { Content } from "antd/lib/layout/layout";
import React from "react";
import { useSelector } from "react-redux";
import { Switch } from "react-router-dom";
import { LoginRequireComponent, SideBar, TopBar } from "../components";
import { RouterLinks } from "../const";
import {
    HomePage,
    ManagerBotPage,
    ManagerFacebookAccountPage,
    ManagerTaskPage,
    ManagerUserPage,
    ManagerTaskCollections
} from "../pages";

export const AdminRouter = () => {
    return (
        <Switch>
            <div className="MainApp">
                <SideBar />
                <div className="MainContent">
                    <TopBar />
                    <Content
                        className="ContentPage"
                        style={{ overflow: "initial" }}
                    >
                        <Switch>
                            <LoginRequireComponent
                                component={HomePage}
                                path={RouterLinks.HOME_PAGE}
                            />
                            <LoginRequireComponent
                                component={ManagerUserPage}
                                path={RouterLinks.MANAGER_USER}
                            />
                            <LoginRequireComponent
                                component={ManagerBotPage}
                                path={RouterLinks.MANAGER_BOT}
                            />
                            <LoginRequireComponent
                                component={ManagerFacebookAccountPage}
                                path={RouterLinks.MANAGER_ACCOUNT_FACEBOOK}
                            />
                            <LoginRequireComponent
                                component={ManagerTaskCollections}
                                path={RouterLinks.MANAGER_TASK_COLLECTIONS}
                            />
                            <LoginRequireComponent
                                component={ManagerTaskPage}
                                path={RouterLinks.MANAGER_TASK}
                            />
                        </Switch>
                    </Content>
                </div>
            </div>
        </Switch>
    );
};
