import React from 'react';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import { BiReset } from 'react-icons/bi';
import { IoChevronBack } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useActions } from '../../redux';
import { showConfirm } from '../../components';
import { Link } from 'react-router-dom';
import { RouterLinks } from '../../const';

export const TopBarControl = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const setModalCurrentTaskState = (state: any) => {
        dispatch(actions.ManagerTasks.setModalCurrentTask(state));
    };

    // list account
    const textSearch = useSelector(
        (state: any) => state.manager_task.textSearch,
    );

    return (
        <div className="MUP_ActionControl">
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                }}
            >
                <Link
                    to={RouterLinks.MANAGER_TASK_COLLECTIONS}
                    className="MUP_AddButton"
                    style={{
                        backgroundColor: 'gray',
                        color: 'white',
                        marginRight: 5,
                    }}
                >
                    <IoChevronBack className="Icon" />
                    Quay lại danh sách đầu việc
                </Link>
                <div
                    className="MUP_AddButton"
                    style={{
                        backgroundColor: '#3399ff',
                        color: 'white',
                        marginRight: 5,
                    }}
                    onClick={() => {
                        // dispatch(
                        //     actions.ManagerTasks.setDefaultCurrentTask()
                        // );
                        // setModalCurrentTaskState(true);
                        if (props.setAddModalVisible)
                            props.setAddModalVisible(true);
                    }}
                >
                    <AiOutlineUserAdd className="Icon" />
                    Thêm việc
                </div>
            </div>
            <div className="MUP_SearchBox">
                <input
                    type="text"
                    value={textSearch}
                    onChange={(e) => {
                        dispatch(
                            actions.ManagerTasks.updateTextSearch(
                                e.target.value,
                            ),
                        );
                    }}
                    placeholder="Nhập để tìm kiếm"
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            dispatch(actions.ManagerTasks.searchTask());
                        }
                    }}
                />
                <FiSearch
                    className="MUPS_Icon"
                    onClick={() => {
                        dispatch(actions.ManagerTasks.searchTask());
                    }}
                />
            </div>
        </div>
    );
};
