import createApiServices from "../createApiServices";

const api = createApiServices();

const GetListUsers = (pageSize: any = 10, pageIndex: any = 1, keyword: any = '') => {
    return api.makeAuthRequest({
        url: `manager-list-users?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${keyword}`,
        method: "GET",
    });
};

const GetDetailUsers = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-list-users/${id}`,
        method: "GET",
    });
};

const InsertUser = (user: any) => {
    let form_data = new FormData();
    for (let key in user) {
        if (key !== "_id") form_data.append(key, user[key]);
    }

    return api.makeAuthRequest({
        url: "manager-list-users",
        method: "POST",
        data: form_data,
    });
};

const UpdateUser = (id: any, user: any) => {
    let form_data = new FormData();
    for (let key in user) {
        form_data.append(key, user[key]);
    }

    return api.makeAuthRequest({
        url: `manager-list-users/update-user/${id}`,
        method: "PUT",
        data: form_data,
    });
};

const RemoveUser = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-list-users/remove-user/${id}`,
        method: "DELETE",
    });
};

const ChangePassword = (
    id: any,
    new_password: any = ""
) => {
    return api.makeAuthRequest({
        url: `manager-list-users/change-password/${id}`,
        method: "PUT",
        data: {
            new_password
        },
    });
};

export const Users = {
    GetListUsers,
    GetDetailUsers,
    InsertUser,
    UpdateUser,
    RemoveUser,
    ChangePassword,
};
