const prefix = 'auth/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    LOGIN: prefix + 'LOGIN',
    LOGOUT: prefix + 'LOGOUT',

    CHECK_SESSION: prefix + 'check_session',

    CHANGE_PASSWORD: prefix + 'change_password',

    UPDATE_CURRENT_USER_INFO: prefix + 'update_current_user_info',
    SAVE_CURRENT_USER_INFO: prefix + 'save_current_user_info'
}

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    login: (username = "", password = "") => {
        return {
            type: type.LOGIN,
            payload: {
                username,
                password
            }
        }
    },
    logout: () => {
        return {
            type: type.LOGOUT,
            payload: {

            }
        }
    },

    checkSession: () => {
        return {
            type: type.CHECK_SESSION,
            payload: {
                
            }
        }
    },

    changePassword: (old_password = '', new_password = '') => {
        return {
            type: type.CHANGE_PASSWORD,
            payload: {
                old_password,
                new_password
            }
        }
    },

    updateCurrentUserInfo: (info ={})=>{
        return {
            type: type.UPDATE_CURRENT_USER_INFO,
            payload: {
                info
            }
        }
    },
    saveCurrentUserInfo: () => {
        return {
            type: type.SAVE_CURRENT_USER_INFO,
            payload: {
                
            }
        }
    }
}

export const AuthActions = action

export default {
    type,
    action
}