import actions from "./actions";

const default_bot: any = {
    _id: null,
    name: "",
    username: "",
    password: "",
    confirm_password: "",
    allow_working: "allow",
    status: 1
};

const initState = {
    list_bots: [],
    loading: false,

    pageSize: 10,
    pageIndex: 1,
    total: 0,

    textSearch: "",
    lastSearch: "",

    current_bot: default_bot,

    modal_current_bot_state: false,
    modal_change_password_state: false,
};

const reducer = (state = initState, action: any) => {
    switch (action.type) {
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...action.payload.state,
            };

        case actions.type.SET_DEFAULT_STATE:
            return initState;

        case actions.type.LOAD_LIST_BOTS:
            return {
                ...state,
                ...{
                    loading: true,
                },
            };

        case actions.type.LOAD_LIST_BOTS_SUCCESS:
            return {
                ...state,
                ...{
                    list_bots: action.payload.bots,
                    loading: false,
                },
            };

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: action.payload.pageIndex,
                },
            };

        case actions.type.UPDATE_PAGE_SIZE:
            return {
                ...state,
                ...{
                    pageIndex: 1,
                    pageSize: action.payload.pageSize,
                },
            };

        case actions.type.SET_DEFAULT_CURRENT_BOT:
            return {
                ...state,
                ...{
                    current_bot: default_bot,
                },
            };

        case actions.type.SET_CURRENT_BOT:
            return {
                ...state,
                ...{
                    current_bot: action.payload.bot,
                },
            };

        case actions.type.UPDATE_CURRENT_BOT:
            return {
                ...state,
                ...{
                    current_bot: {
                        ...state.current_bot,
                        ...action.payload.bot,
                    },
                },
            };

        case actions.type.SAVE_CURRENT_BOT:
            return state;

        case actions.type.REMOVE_BOT:
            return state;

        case actions.type.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: action.payload.textSearch,
                },
            };

        case actions.type.SEARCH_BOT:
            return {
                ...state,
                ...{
                    lastSearch: state.textSearch,
                },
            };

        case actions.type.SET_MODAL_CURRENT_BOT_STATE:
            return {
                ...state,
                ...{
                    modal_current_bot_state: action.payload.state,
                },
            };

        case actions.type.SET_MODAL_CHANGE_PASSWORD:
            return {
                ...state,
                ...{
                    modal_change_password_state: action.payload.state,
                },
            };

        case actions.type.CHANGE_PASSWORD_CURRENT_BOT:
            return state;

        default:
            return state;
    }
};

export default reducer;
