const correctAccount = (account: any) => {
    account.UID = account.UID ? account.UID.trim() : "";
    account.USERNAME = account.USERNAME ? account.USERNAME.trim() : "";
    account.PASSWORD = account.PASSWORD ? account.PASSWORD.trim() : "";
    account.SECRET = account.SECRET ? account.SECRET.trim() : "";

    return account;
};

export const validate = (account: any) => {
    account = correctAccount(account);

    const ErrorResult = (message = "") => {
        return {
            account,
            result: false,
            message,
        };
    };

    if (account.UID === "") return ErrorResult("UID không được để trống");

    if (account.USERNAME === "")
        return ErrorResult("Tên đăng nhập không được để trống");

    if (account.PASSWORD === "")
        return ErrorResult("Mật khẩu không được để trống");

    if (account.SECRET === "")
        return ErrorResult("Mã khoá bí mật không được để trống");

    return {
        account,
        result: true,
        message: "",
    };
};
