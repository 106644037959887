import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useActions } from "../../redux";

// scss
import "./index.scss";
import { ModalCurrentAccountComponent } from "./modal-current-account-component";
import { Pagination } from "./pagination";
import { TableListAccout } from "./table-list-accounts";
import { TopBarControl } from "./top-bar-control";

export const ManagerFacebookAccountPage = () => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    // load
    useEffect(() => {
        dispatch(actions.ManagerAccountsActions.loadListAccounts());
    }, []);

    return (
        <div className="ManagerBotPage">
            <TopBarControl />
            <TableListAccout />
            <Pagination />
            <ModalCurrentAccountComponent />
        </div>
    );
};
