const correctEntity = (entity: any) => {
    entity.name = entity.name ? entity.name.trim() : "";
    entity.description = entity.description ? entity.description.trim() : "";

    return entity;
};

export const validate = (entity: any) => {
    entity = correctEntity(entity);

    const ErrorResult = (message = "") => {
        return {
            task_collection: entity,
            result: false,
            message,
        };
    };

    if (entity.name === "") return ErrorResult("Tên không được để trống");

    return {
        task_collection: entity,
        result: true,
        message: "",
    };
};
