import React from "react";
import { Table } from "antd";
import { FaEye, FaRegEdit, FaUserSecret } from "react-icons/fa";
import { FiTrash2 } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";
import { showConfirm } from "../../components";
import { CommonHelper } from "../../utils";

const columns = [
    {
        title: "TT",
        dataIndex: "stt",
    },
    {
        title: "Loại việc",
        dataIndex: "type",
        render: (type: string) => {
            return type.toUpperCase();
        },
    },
    {
        title: "Tham số",
        dataIndex: "payload",
        render: (payload: any) => {
            payload = CommonHelper.String.convertJsonToObject(payload);
            let keys = Object.keys(payload);

            return (
                <div>
                    {keys.map((key, index) => {
                        return (
                            <div key={index}>
                                <span
                                    style={{ fontWeight: 600 }}
                                >{`${key}: `}</span>
                                {payload[key]}
                            </div>
                        );
                    })}
                </div>
            );
        },
    },
    {
        title: "Trạng thái",
        dataIndex: "status",
        render: (status: any) => {
            let color = "black";
            if (status === "completed") color = "green";
            if (status === "cancel") color = "red";

            return <div style={{ color, fontWeight: 550 }}>{status}</div>;
        },
    },
    {
        title: "Thời gian tạo",
        dataIndex: "created_date",
        render: (created_date: any) => {
            return (
                <div>
                    {CommonHelper.Date.convertIsoDateToDatetime(created_date)}
                </div>
            );
        },
    },
    {
        title: "Hành động",
        width: 200,
        render: (entity: any) => {
            return (
                <div className="ListActions">
                    {/* <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            // if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaUserSecret className="Icon" />
                        Copy 2fa code
                    </div> */}
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaEye className="Icon" />
                        Xem
                    </div>
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#ff3300",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleRemove) entity.handleRemove();
                        }}
                    >
                        <FiTrash2 className="Icon" />
                        Xoá
                    </div>
                </div>
            );
        },
    },
];

export const TableListTasks = (props: any) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const loading = useSelector((state: any) => state.manager_task.loading);
    const tasks = useSelector((state: any) => state.manager_task.tasks);

    const setModalState = (state: any) => {
        dispatch(actions.ManagerTasks.setModalCurrentTask(state));
    };

    // current account
    const setCurrentTask = (task: any) => {
        dispatch(actions.ManagerTasks.setCurrentTask(task));
        dispatch(actions.ManagerTasks.loadListLogs({ task_id: task._id }));
    };

    // pagination
    const pageIndex = useSelector(
        (state: any) => state.manager_task.page_index
    );
    const pageSize = useSelector((state: any) => state.manager_task.page_size);

    // add handle
    let items = tasks;
    let data = [];
    for (let index = 0; index < items.length; index++) {
        data.push(items[index]);

        data[index] = {
            ...data[index],
            ...{
                stt: pageSize * (pageIndex - 1) + index + 1,
                handleEdit: () => {
                    setCurrentTask(items[index]);
                    setModalState(true);
                },
                handleRemove: () => {
                    setCurrentTask(items[index]);
                    showConfirm({
                        title: "Bạn chắc chắn xoá công việc này này?",
                        content: "",
                        onOk: () => {
                            dispatch(
                                actions.ManagerTasks.removeTask(
                                    items[index]._id
                                )
                            );
                        },
                        onCancel: () => {},
                    });
                },
            },
        };
    }

    return (
        <Table
            columns={columns}
            rowKey={(record) => record._id}
            dataSource={data}
            pagination={false}
            loading={loading}
            style={{
                flex: 1,
                cursor: "pointer"
            }}
            onRow={(data: any, index: any) => {
                return {
                    onClick: (event) => {
                        setCurrentTask(items[index]);
                        setModalState(true);
                    },
                };
            }}
        />
    );
};
