import React from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ModalComponent } from "../../components";
import { useActions } from "../../redux";
import { ListBotAllowWorkingEnum } from "../../const";

const { Option } = Select;

export const ModalCurrentBot = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    // const [modalState, setModalState] = useState(false);
    const modalState = useSelector(
        (state: any) => state.manager_bots.modal_current_bot_state
    );
    const setModalState = (state: any) => {
        dispatch(actions.ManagerBotsActions.setModalCurrentBotState(state));
    };

    // render
    const _renderModalCurrentBot = () => {
        // current account
        let currentBot = useSelector(
            (state: any) => state.manager_bots.current_bot
        );
        const updateCurrentBot = (bot: any) => {
            dispatch(actions.ManagerBotsActions.updateCurrentBot(bot));
        };

        return (
            <div className="ModalCurrentUser">
                {currentBot._id && (
                    <div className="RowInfo">
                        <div className="RowTitle">_id</div>
                        <div className="RowContent">{currentBot._id}</div>
                    </div>
                )}
                <div className="RowInfo">
                    <div className="RowTitle">Tên</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentBot.name}
                        onChange={(e) => {
                            updateCurrentBot({
                                name: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Tên đăng nhập</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentBot.username}
                        onChange={(e) => {
                            updateCurrentBot({
                                username: e.target.value,
                            });
                        }}
                    />
                </div>
                {!currentBot._id && (
                    <React.Fragment>
                        <div className="RowInfo">
                            <div className="RowTitle">Mật khẩu</div>
                            <input
                                type="password"
                                className="RowContent"
                                value={currentBot.password}
                                onChange={(e) => {
                                    updateCurrentBot({
                                        password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="RowInfo">
                            <div className="RowTitle">Xác nhận mật khẩu</div>
                            <input
                                type="password"
                                className="RowContent"
                                value={currentBot.confirm_password}
                                onChange={(e) => {
                                    updateCurrentBot({
                                        confirm_password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
                <div className="RowInfo">
                    <div className="RowTitle">Quyền làm việc</div>
                    <Select
                        className="RowContentSelect"
                        value={currentBot.allow_working}
                        onChange={(value) => {
                            updateCurrentBot({
                                allow_working: value,
                            });
                        }}
                    >
                        {ListBotAllowWorkingEnum.map((_enum, index) => {
                            return (
                                <Option value={_enum} key={index}>
                                    {_enum}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
            </div>
        );
    };

    return (
        <ModalComponent
            title={"Chi tiết Bot"}
            isModalVisible={modalState}
            onOk={() => {
                dispatch(actions.ManagerBotsActions.saveCurrentBot());
            }}
            onCancel={() => {
                setModalState(false);
            }}
            cancelText="Huỷ"
            okText="Lưu"
        >
            {_renderModalCurrentBot()}
        </ModalComponent>
    );
};
