const prefix = 'ui_global/'

const types = {
    SET_COLLAPSE: prefix + 'SET_COLLAPSE'
}

const action = {
    SetCollapse: (collapse = false) => {
        return {
            type: types.SET_COLLAPSE,
            payload: {
                collapse
            }
        }
    },
}

const actions = {
    types,
    action
}

export default actions;

export const UIGlobalActions = action