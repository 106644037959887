const prefix = "manager-bots/";

const type = {
    UPDATE_STATE: prefix + "update_state",
    SET_DEFAULT_STATE: prefix + 'set_default_state',

    LOAD_LIST_BOTS: prefix + "load_list_bots",
    LOAD_LIST_BOTS_SUCCESS: prefix + "load_list_bot_success",

    UPDATE_PAGE_SIZE: prefix + "update_page_size",
    UPDATE_PAGE_INDEX: prefix + "update_page_index",

    SET_DEFAULT_CURRENT_BOT: prefix + "set_default_current_bot",
    SET_CURRENT_BOT: prefix + "set_current_bot",
    UPDATE_CURRENT_BOT: prefix + "update_current_bot",
    SAVE_CURRENT_BOT: prefix + "save_current_bot",
    REMOVE_BOT: prefix + "remove_bot",

    UPDATE_TEXT_SEARCH: prefix + "update_text_search",
    SEARCH_BOT: prefix + "search_bot",

    SET_MODAL_CURRENT_BOT_STATE: prefix + "set_modal_current_bot_state",
    SET_MODAL_CHANGE_PASSWORD: prefix + 'set_modal_change_password',
    CHANGE_PASSWORD_CURRENT_BOT: prefix + 'change_password_current_bot'
};

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state,
            },
        };
    },
    setDefaultState: () => {
        return {
            type: type.SET_DEFAULT_STATE,
            payload: {
                
            }
        }
    },

    loadListBots: (params = {}) => {
        return {
            type: type.LOAD_LIST_BOTS,
            payload: {
                params,
            },
        };
    },
    loadListBotsSuccess: (bots = []) => {
        return {
            type: type.LOAD_LIST_BOTS_SUCCESS,
            payload: {
                bots,
            },
        };
    },

    updatePageSize: (pageSize = 10) => {
        return {
            type: type.UPDATE_PAGE_SIZE,
            payload: {
                pageSize,
            },
        };
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex,
            },
        };
    },

    setDefaultCurrentBot: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_BOT,
            payload: {},
        };
    },
    setCurrentBot: (bot = {}) => {
        return {
            type: type.SET_CURRENT_BOT,
            payload: {
                bot,
            },
        };
    },
    updateCurrentBot: (bot = {}) => {
        return {
            type: type.UPDATE_CURRENT_BOT,
            payload: {
                bot,
            },
        };
    },
    saveCurrentBot: () => {
        return {
            type: type.SAVE_CURRENT_BOT,
            payload: {},
        };
    },
    removeBot: (id = null) => {
        return {
            type: type.REMOVE_BOT,
            payload: {
                id,
            },
        };
    },

    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch,
            },
        };
    },
    searchBot: () => {
        return {
            type: type.SEARCH_BOT,
            payload: {},
        };
    },

    setModalCurrentBotState: (state = false) => {
        return {
            type: type.SET_MODAL_CURRENT_BOT_STATE,
            payload: {
                state,
            },
        };
    },
    setModalChangePassword: (state = false) => {
        return {
            type: type.SET_MODAL_CHANGE_PASSWORD,
            payload: {
                state
            }
        }
    },

    changePasswordCurrentBot: (password = "") => {
        return {
            type: type.CHANGE_PASSWORD_CURRENT_BOT,
            payload: {
                password
            }
        }
    }
};

export const ManagerBotsActions = action;

export default {
    type,
    action,
};
