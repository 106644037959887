const prefix = "manager-fb-account/";

const type = {
    UPDATE_STATE: prefix + "update_state",
    SET_DEFAULT_STATE: prefix + 'set_default_state',

    LOAD_LIST_ACCOUNTS: prefix + "load_list_accounts",
    LOAD_LIST_ACCOUNTS_SUCCESS: prefix + "load_list_account_success",

    UPDATE_PAGE_SIZE: prefix + "update_page_size",
    UPDATE_PAGE_INDEX: prefix + "update_page_index",

    SET_DEFAULT_CURRENT_ACCOUNT: prefix + "set_default_current_account",
    SET_CURRENT_ACCOUNT: prefix + "set_current_account",
    UPDATE_CURRENT_ACCOUNT: prefix + "update_current_account",
    SAVE_CURRENT_ACCOUNT: prefix + "save_current_account",
    REMOVE_ACCOUNT: prefix + "remove_account",

    UPDATE_TEXT_SEARCH: prefix + "update_text_search",
    SEARCH_ACCOUNT: prefix + "search_account",

    SET_MODAL_CURRENT_ACCOUNT_STATE: prefix + "set_modal_current_account_state",

    RESET_STATUS_ALL_ACCOUNT: prefix + 'reset_status_all_account'
};

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state,
            },
        };
    },
    setDefaultState: () => {
        return {
            type: type.SET_DEFAULT_STATE,
            payload: {
                
            }
        }
    },

    loadListAccounts: (params = {}) => {
        return {
            type: type.LOAD_LIST_ACCOUNTS,
            payload: {
                params,
            },
        };
    },
    loadListAccountsSuccess: (accounts = []) => {
        return {
            type: type.LOAD_LIST_ACCOUNTS_SUCCESS,
            payload: {
                accounts,
            },
        };
    },

    updatePageSize: (pageSize = 10) => {
        return {
            type: type.UPDATE_PAGE_SIZE,
            payload: {
                pageSize,
            },
        };
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex,
            },
        };
    },

    setDefaultCurrentAccount: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_ACCOUNT,
            payload: {},
        };
    },
    setCurrentAccount: (account = {}) => {
        return {
            type: type.SET_CURRENT_ACCOUNT,
            payload: {
                account,
            },
        };
    },
    updateCurrentAccount: (account = {}) => {
        return {
            type: type.UPDATE_CURRENT_ACCOUNT,
            payload: {
                account,
            },
        };
    },
    saveCurrentAccount: () => {
        return {
            type: type.SAVE_CURRENT_ACCOUNT,
            payload: {},
        };
    },
    removeAccount: (id = null) => {
        return {
            type: type.REMOVE_ACCOUNT,
            payload: {
                id,
            },
        };
    },

    updateTextSearch: (textSearch = "") => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch,
            },
        };
    },
    searchAccount: () => {
        return {
            type: type.SEARCH_ACCOUNT,
            payload: {},
        };
    },

    setModalCurrentAccountState: (state = false) => {
        return {
            type: type.SET_MODAL_CURRENT_ACCOUNT_STATE,
            payload: {
                state,
            },
        };
    },

    resetStatusAllAccount: () => {
        return {
            type: type.RESET_STATUS_ALL_ACCOUNT,
            payload: {
                
            }
        }
    }
};

export const ManagerAccountsActions = action;

export default {
    type,
    action,
};
