
import { ROLE_TYPES } from '../../const';
import actions from './actions'

const default_user: any = {
    _id: null,
    username: "",
    email: "",
    sex: 1,
    address: "",
    fullname: "",
    avatar: "https://st4.depositphotos.com/4329009/19956/v/600/depositphotos_199564354-stock-illustration-creative-vector-illustration-default-avatar.jpg",
    role: ROLE_TYPES.MONITOR,
    password: "",
    confirm_password: "",
    file: null
};

const initState = {
    list_users: [],
    loading: false,

    pageSize: 10,
    pageIndex: 1,
    total: 0,

    textSearch: "",
    lastSearch: "",

    current_user: default_user,

    modal_current_user_state: false,
    modal_change_password_state: false
}

const reducer = (state = initState, action: any) => {
    

    switch (action.type){
        case actions.type.UPDATE_STATE:
            return {
                ...state,
                ...action.payload.state
            }

        case actions.type.SET_DEFAULT_STATE:
            return initState

        case actions.type.LOAD_LIST_USERS:
            return {
                ...state,
                ...{
                    loading: true
                }
            } 

        case actions.type.LOAD_LIST_USERS_SUCCESS:
            return {
                ...state,
                ...{
                    list_users: action.payload.users,
                    loading: false
                }
            }

        case actions.type.UPDATE_PAGE_INDEX:
            return {
                ...state,
                ...{
                    pageIndex: action.payload.pageIndex
                }
            }

        case actions.type.UPDATE_PAGE_SIZE:
            return {
                ...state,
                ...{
                    pageIndex: 1,
                    pageSize: action.payload.pageSize
                }
            }

        case actions.type.SET_DEFAULT_CURRENT_USER:
            return {
                ...state,
                ...{
                    current_user: default_user
                }
            }

        case actions.type.SET_CURRENT_USER:
            return {
                ...state,
                ...{
                    current_user: action.payload.user
                }
            }

        case actions.type.UPDATE_CURRENT_USER:
            return {
                ...state,
                ...{
                    current_user: {
                        ...state.current_user,
                        ...action.payload.user
                    }
                }
            }

        case actions.type.SAVE_CURRENT_USER:
            return state 

        case actions.type.REMOVE_USER:
            return state 

        case actions.type.UPDATE_TEXT_SEARCH:
            return {
                ...state,
                ...{
                    textSearch: action.payload.textSearch
                }
            }

        case actions.type.SEARCH_USER:
            return {
                ...state,
                ...{
                    lastSearch: state.textSearch
                }
            }

        case actions.type.SET_MODAL_CURRENT_USER_STATE:
            return {
                ...state,
                ...{
                    modal_current_user_state: action.payload.state
                }
            }

        case actions.type.SET_MODAL_CHANGE_PASSWORD:
            return {
                ...state,
                ...{
                    modal_change_password_state: action.payload.state
                }
            }

        case actions.type.CHANGE_PASSWORD_CURRENT_USER:
            return state

        default:
            return state 
    }
}

export default reducer
