import createApiServices from "../createApiServices";

const api = createApiServices();

const GetListLog = (pageSize = 10, pageIndex = 1, textSearch = "") => {
    return api.makeAuthRequest({
        url: `manager-task-logs?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${textSearch}`,
        method: "GET",
    });
};

const GetListLogOfTask = (task_id = "",pageSize = 10, pageIndex = 1, textSearch = "")=>{
    return api.makeAuthRequest({
        url: `manager-task-logs/log-of-task/${task_id}?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${textSearch}`,
        method: "GET"
    })
}

const GetDetailLog = (id = "") => {
    return api.makeAuthRequest({
        url: `manager-task-logs/${id}`,
        method: "GET",
    });
};

const RemoveLog = (id: any) => {
    return api.makeAuthRequest({
        url: `manager-task-logs/${id}`,
        method: "PUT",
    });
};

export const Log = {
    GetListLog,
    GetListLogOfTask,
    GetDetailLog,
    RemoveLog,
};
