import React, { useEffect, useState } from "react";

import { Table, Select } from "antd";

import "./index.scss";
import {
    ModalComponent,
    PaginationComponent,
    showConfirm,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";

import { FaRegEdit } from "react-icons/fa";
import { FiTrash2, FiSearch, FiUploadCloud } from "react-icons/fi";
import { AiOutlineUserAdd } from "react-icons/ai";
import { useActions } from "../../redux";
import { ListRoles } from "../../const";
import { APIServices, NotificationsService } from "../../utils";

const { Option } = Select;

const columns = [
    {
        // title: "Ảnh đại diện",
        dataIndex: "avatar",
        render: (avatar: any) => {
            avatar = APIServices.Images.getImageUrl(avatar);

            return (
                <img
                    src={avatar}
                    alt="avatar"
                    style={{
                        height: 50,
                        width: 50,
                        borderRadius: 25,
                    }}
                />
            );
        },
    },
    {
        title: "Họ và tên",
        dataIndex: "fullname",
        render: (name: any) => `${name}`,
    },
    {
        title: "Tên đăng nhập",
        dataIndex: "username",
    },

    {
        title: "Giới tính",
        dataIndex: "sex",
        render: (sex: any) => {
            let ans = sex === 1 ? "Nam" : "Nữ";
            return ans;
        },
    },
    {
        title: "Email",
        dataIndex: "email",
    },
    // {
    //     title: "Địa chỉ",
    //     dataIndex: "address",
    // },
    {
        title: "Quyền",
        dataIndex: "role",
    },
    {
        title: "Hành động",
        width: 330,
        render: (entity: any) => {
            return (
                <div className="ListActions">
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleChangePassword)
                                entity.handleChangePassword();
                        }}
                    >
                        <FaRegEdit className="Icon" />
                        Đổi mật khẩu
                    </div>
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#3399ff",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleEdit) entity.handleEdit();
                        }}
                    >
                        <FaRegEdit className="Icon" />
                        Sửa
                    </div>
                    <div
                        className="Button"
                        style={{
                            backgroundColor: "#ff3300",
                            color: "white",
                        }}
                        onClick={() => {
                            if (entity.handleRemove) entity.handleRemove();
                        }}
                    >
                        <FiTrash2 className="Icon" />
                        Xoá
                    </div>
                </div>
            );
        },
    },
];

export const ManagerUserPage = () => {
    // local
    let [_password, _setPassword] = useState("");
    let [_confirm_password, _setConfirmPassword] = useState("");

    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    // const [modalState, setModalState] = useState(false);
    const modalState = useSelector(
        (state: any) => state.manager_users.modal_current_user_state
    );
    const setModalState = (state: any) => {
        dispatch(actions.ManagerUsersActions.setModalCurrentUserState(state));
    };

    const modalChangePasswordState = useSelector(
        (state: any) => state.manager_users.modal_change_password_state
    );
    const setModalChangePasswordState = (state: any) => {
        dispatch(actions.ManagerUsersActions.setModalChangePassword(state));

        _setPassword("");
        _setConfirmPassword("");
    };

    const loading = useSelector((state: any) => state.manager_users.loading);
    const currentUser = useSelector(
        (state: any) => state.manager_users.current_user
    );
    const setCurrentUser = (user: any) => {
        dispatch(actions.ManagerUsersActions.setCurrentUser(user));
    };

    const textSearch = useSelector(
        (state: any) => state.manager_users.textSearch
    );
    const list_users = useSelector(
        (state: any) => state.manager_users.list_users
    );
    const total = useSelector((state: any) => state.manager_users.total);
    const pageIndex = useSelector(
        (state: any) => state.manager_users.pageIndex
    );
    const pageSize = useSelector((state: any) => state.manager_users.pageSize);

    useEffect(() => {
        dispatch(actions.ManagerUsersActions.loadListUsers());
    }, []);

    // add handle
    let data = [];
    for (let index = 0; index < list_users.length; index++) {
        data.push(list_users[index]);
        data[index] = {
            ...data[index],
            ...{
                handleChangePassword: () => {
                    setCurrentUser(list_users[index]);
                    setModalChangePasswordState(true);
                },
                handleEdit: () => {
                    setCurrentUser(list_users[index]);
                    setModalState(true);
                },
                handleRemove: () => {
                    setCurrentUser(list_users[index]);
                    showConfirm({
                        title: "Bạn chắc chắn xoá người dùng này?",
                        content: "",
                        onOk: () => {
                            dispatch(
                                actions.ManagerUsersActions.removeUser(
                                    list_users[index]._id
                                )
                            );
                        },
                        onCancel: () => {},
                    });
                },
            },
        };
    }

    // render
    const _renderModelCurrentUser = () => {
        if (!currentUser) return;

        const avatar = APIServices.Images.getImageUrl(currentUser.avatar);

        const updateCurrentUser = (user: any = {}) => {
            dispatch(actions.ManagerUsersActions.updateCurrentUser(user));
        };

        const handleImageChange = (e: any) => {
            e.preventDefault();

            for (let index = 0; index < e.target.files.length; index++) {
                let file = e.target.files[index];
                let reader = new FileReader();

                reader.readAsDataURL(file);

                if (file.type.indexOf("image/") === 0) {
                    reader.onloadend = () => {
                        let avatar = reader.result;
                        updateCurrentUser({
                            avatar,
                            file,
                        });
                    };
                } else {
                    NotificationsService.error("File không đúng định dạng");
                }
            }
        };

        return (
            <div className="ModalCurrentUser">
                <div className="RowAvatarInfo">
                    {avatar != "" && (
                        <img
                            className="AvatarImage"
                            src={avatar}
                            alt="avatar"
                        />
                    )}

                    <input
                        type="file"
                        name="file"
                        id="file"
                        style={{
                            display: "none",
                        }}
                        onChange={(e) => handleImageChange(e)}
                    />

                    <label htmlFor={"file"} className="Button">
                        <FiUploadCloud className="Icon" />
                        Chọn avatar
                    </label>
                </div>

                <div className="RowInfo">
                    <div className="RowTitle">Họ và tên</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentUser.fullname}
                        onChange={(e) => {
                            updateCurrentUser({
                                fullname: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Email</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentUser.email}
                        onChange={(e) => {
                            updateCurrentUser({
                                email: e.target.value,
                            });
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Tên đăng nhập</div>
                    <input
                        type="text"
                        className="RowContent"
                        value={currentUser.username}
                        onChange={(e) => {
                            updateCurrentUser({
                                username: e.target.value,
                            });
                        }}
                    />
                </div>
                {currentUser._id == null && (
                    <>
                        <div className="RowInfo">
                            <div className="RowTitle">Mật khẩu</div>
                            <input
                                type="password"
                                className="RowContent"
                                value={currentUser.password}
                                onChange={(e) => {
                                    updateCurrentUser({
                                        password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="RowInfo">
                            <div className="RowTitle">Xác nhận mật khẩu</div>
                            <input
                                type="password"
                                className="RowContent"
                                value={currentUser.confirm_password}
                                onChange={(e) => {
                                    updateCurrentUser({
                                        confirm_password: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </>
                )}

                <div className="RowInfo">
                    <div className="RowTitle">Giới tính</div>
                    <Select
                        className="RowContentSelect"
                        value={currentUser.sex}
                        onChange={(value) => {
                            updateCurrentUser({
                                sex: value,
                            });
                        }}
                    >
                        <Option value={0}>Nữ</Option>
                        <Option value={1}>Nam</Option>
                    </Select>
                </div>

                <div className="RowInfo">
                    <div className="RowTitle">Phân quyền</div>
                    <Select
                        className="RowContentSelect"
                        value={currentUser.role}
                        onChange={(value) => {
                            updateCurrentUser({
                                role: value,
                            });
                        }}
                    >
                        {ListRoles.map((role, index) => {
                            return (
                                <Option key={index} value={role}>
                                    {role}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Địa chỉ</div>
                    <textarea
                        className="RowAreaContent"
                        value={currentUser.address}
                        onChange={(e) => {
                            updateCurrentUser({
                                address: e.target.value,
                            });
                        }}
                    />
                </div>
            </div>
        );
    };

    const _renderModelChangePassword = () => {
        return (
            <div className="ListUser_ModalChangePassword">
                <div className="RowInfo">
                    <div className="RowTitle">Mật khẩu mới</div>
                    <input
                        type="password"
                        className="RowContent"
                        value={_password}
                        onChange={(e) => {
                            _setPassword(e.target.value);
                        }}
                    />
                </div>
                <div className="RowInfo">
                    <div className="RowTitle">Xác nhận mật khẩu mới</div>
                    <input
                        type="password"
                        className="RowContent"
                        value={_confirm_password}
                        onChange={(e) => {
                            _setConfirmPassword(e.target.value);
                        }}
                    />
                </div>
            </div>
        );
    };

    return (
        <div className="ManagerUserPage">
            <div className="MUP_ActionControl">
                <div
                    className="MUP_AddButton"
                    style={{
                        backgroundColor: "#3399ff",
                        color: "white",
                    }}
                    onClick={() => {
                        dispatch(
                            actions.ManagerUsersActions.setDefaultCurrentUser()
                        );
                        setModalState(true);
                    }}
                >
                    <AiOutlineUserAdd className="Icon" />
                    Thêm người dùng
                </div>
                <div className="MUP_SearchBox">
                    <input
                        type="text"
                        value={textSearch}
                        onChange={(e) => {
                            dispatch(
                                actions.ManagerUsersActions.updateTextSearch(
                                    e.target.value
                                )
                            );
                        }}
                        placeholder="Nhập để tìm kiếm"
                        onKeyPress={(e) => {
                            if (e.key === "Enter") {
                                dispatch(
                                    actions.ManagerUsersActions.searchUser()
                                );
                            }
                        }}
                    />
                    <FiSearch
                        className="MUPS_Icon"
                        onClick={() => {
                            dispatch(actions.ManagerUsersActions.searchUser());
                        }}
                    />
                </div>
            </div>

            <Table
                columns={columns}
                rowKey={(record) => record._id}
                dataSource={data}
                pagination={false}
                loading={loading}
                style={{
                    flex: 1,
                }}
            />

            <PaginationComponent
                total={total}
                onChange={(_page: any, _pageSize: any) => {
                    if (_pageSize !== pageSize) {
                        dispatch(
                            actions.ManagerUsersActions.updatePageSize(
                                _pageSize
                            )
                        );
                        return;
                    }

                    if (_page !== pageIndex) {
                        dispatch(
                            actions.ManagerUsersActions.updatePageIndex(_page)
                        );
                    }
                }}
                current={pageIndex}
                pageSize={pageSize}
                showSizeChanger={true}
            />

            <ModalComponent
                title={"Chi tiết người dùng"}
                isModalVisible={modalState}
                onOk={() => {
                    dispatch(actions.ManagerUsersActions.saveCurrentUser());
                }}
                onCancel={() => {
                    setModalState(false);
                }}
                cancelText="Huỷ"
                okText="Lưu"
            >
                {_renderModelCurrentUser()}
            </ModalComponent>

            <ModalComponent
                title={"Thay đổi mật khẩu"}
                isModalVisible={modalChangePasswordState}
                onOk={() => {
                    if (_password === "") {
                        NotificationsService.error(
                            "Mật khẩu không được để trống"
                        );
                        return;
                    }
                    if (_password !== _confirm_password) {
                        NotificationsService.error(
                            "Xác nhận mật khẩu không chính xác"
                        );
                        return;
                    }

                    setModalChangePasswordState(false);
                    dispatch(
                        actions.ManagerUsersActions.changePasswordCurrentUser(
                            _password
                        )
                    );
                }}
                onCancel={() => {
                    setModalChangePasswordState(false);
                }}
                cancelText="Huỷ"
                okText="Lưu"
                width={360}
            >
                {_renderModelChangePassword()}
            </ModalComponent>
        </div>
    );
};
