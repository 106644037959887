import createApiServices from "../createApiServices";

const api = createApiServices();

const GetListAccounts = (pageSize: any = 10, pageIndex: any = 1, keyword: any = '') => {
    return api.makeAuthRequest({
        url: `facebook-accounts?pageSize=${pageSize}&pageIndex=${pageIndex}&keyword=${keyword}`,
        method: "GET",
    });
};

const GetDetailAccounts = (id: any) => {
    return api.makeAuthRequest({
        url: `facebook-accounts/${id}`,
        method: "GET",
    });
};

const InsertAccount = (user: any) => {
    let form_data = new FormData();
    for (let key in user) {
        if (key !== "_id") form_data.append(key, user[key]);
    }

    return api.makeAuthRequest({
        url: "facebook-accounts",
        method: "POST",
        data: form_data,
    });
};

const UpdateAccount = (id: any, account: any) => {
    let form_data = new FormData();
    for (let key in account) {
        form_data.append(key, account[key]);
    }

    return api.makeAuthRequest({
        url: `facebook-accounts/${id}`,
        method: "PUT",
        data: form_data,
    });
};

// const InsertManyAccountByFile = (file: any) => {
//     let form_data = new FormData();
//     form_data.append()
// }

const RemoveAccount = (id: any) => {
    return api.makeAuthRequest({
        url: `facebook-accounts/${id}`,
        method: "DELETE",
    });
};

const ResetStatusAllAccount = () => {
    return api.makeAuthRequest({
        url: `facebook-accounts/reset-all-to-unkown`,
        method: "POST"
    })
}

export const Accounts = {
    GetListAccounts,
    GetDetailAccounts,
    InsertAccount,
    RemoveAccount,
    UpdateAccount,
    ResetStatusAllAccount
};
