import { AppConfigs } from "../../../configs";

const getImageUrl = (url: any) => {
    try {
        if (!url) return url;

        if (
            url[0] === "h" &&
            url[1] === "t" &&
            url[2] === "t" &&
            url[3] === "p"
        )
            return url;
        if (
            url[0] === "d" &&
            url[1] === "a" &&
            url[2] === "t" &&
            url[3] === "a"
        )
            return url;

        let server = AppConfigs.ServerConfigs.server;

        return `${server}/files/${url}`;
    } catch (ex: any) {
        return url;
    }
};

export const Images = {
    getImageUrl,
};
