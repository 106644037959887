const prefix = 'manager-tasks/'

const type = {
    UPDATE_STATE: prefix + 'update_state',

    LOAD_LIST_TASK: prefix + 'load_list_task',
    LOAD_LIST_TASK_SUCCESS: prefix + 'load_list_task_success',

    UPDATE_PAGE_INDEX: prefix + 'update_page_index',
    UPDATE_PAGE_SIZE: prefix + 'update_page_size',
    UPDATE_TEXT_SEARCH: prefix + 'update_text_search',

    SEARCH_TASK: prefix + 'search_task',

    SET_DEFAULT_CURRENT_TASK: prefix + 'set_default_current_task',
    SET_CURRENT_TASK: prefix + 'set_current_task',
    UPDATE_CURRENT_TASK: prefix + 'update_current_task',
    REMOVE_TASK: prefix + 'remove_task',
    SAVE_CURRENT_TASK: prefix + 'save_current_task',

    LOAD_LIST_LOGS: prefix + 'load_list_logs',
    LOAD_LIST_LOGS_SUCCESS: prefix + 'load_lisk_logs_success',
    UPDATE_LOG_PAGE_INDEX: prefix + 'update_log_page_index',
    UPDATE_LOG_PAGE_SIZE: prefix + 'update_log_page_size',

    SET_MODAL_CURRENT_TASK: prefix + 'set_modal_current_task',

    LOAD_TASK_COLLECTION_INFO: prefix + 'load_task_collection_info',
    LOAD_TASK_COLLECTION_INFO_SUCCESS: prefix + 'load_task_collection_info_success',

    ADD_TASK: prefix + 'add_task'
}

const action = {
    updateState: (state = {}) => {
        return {
            type: type.UPDATE_STATE,
            payload: {
                state
            }
        }
    },

    // task manager 
    loadListTasks: (params: any = {}) => {
        return {
            type: type.LOAD_LIST_TASK,
            payload: {
                params
            }
        }
    },
    loadListTasksSuccess: (tasks  = []) => {
        return {
            type: type.LOAD_LIST_TASK_SUCCESS,
            payload: {
                tasks
            }
        }
    },
    updatePageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updatePageSize: (pageSize = 10) => {
        return {
            type: type.UPDATE_PAGE_SIZE,
            payload: {
                pageSize
            }
        }
    },
    updateTextSearch: (textSearch = '') => {
        return {
            type: type.UPDATE_TEXT_SEARCH,
            payload: {
                textSearch
            }
        }
    },
    searchTask: () => {
        return {
            type: type.SEARCH_TASK,
            payload: {

            }
        }
    },

    setDefaultCurrentTask: () => {
        return {
            type: type.SET_DEFAULT_CURRENT_TASK,
            payload: {

            }
        }
    },
    setCurrentTask: (task = {}) => {
        return {
            type: type.SET_CURRENT_TASK,
            payload: {
                task
            }
        }
    },
    updateCurrentTask: (task = {}) => {
        return {
            type: type.UPDATE_CURRENT_TASK,
            payload: {
                task
            }
        }
    },
    removeTask: (id = '') => {
        return {
            type: type.REMOVE_TASK,
            payload: {
                id
            }
        }
    },
    saveCurrentTask: () => {
        return {
            type: type.SAVE_CURRENT_TASK,
            payload: {

            }
        }
    },

    loadListLogs: (params = {}) => {
        return {
            type: type.LOAD_LIST_LOGS,
            payload: {
                params
            }
        }
    },
    loadListLogsSuccess: (logs = []) => {
        return {
            type: type.LOAD_LIST_LOGS_SUCCESS,
            payload: {
                logs
            }
        }
    },
    updateLogPageIndex: (pageIndex = 1) => {
        return {
            type: type.UPDATE_LOG_PAGE_INDEX,
            payload: {
                pageIndex
            }
        }
    },
    updateLogPageSize: (pageSize = 10) => {
        return {
            type: type.UPDATE_LOG_PAGE_SIZE,
            payload: {
                pageSize
            }
        }
    },

    setModalCurrentTask: (state = false) => {
        return {
            type: type.SET_MODAL_CURRENT_TASK,
            payload: {
                state
            }
        }
    },

    loadTaskCollectionInfo: (id: any = null) => {
        return {
            type: type.LOAD_TASK_COLLECTION_INFO,
            payload: {
                id
            }
        }
    },
    loadTaskTaskCollectionInfoSuccess: (task_collection: any) => {
        return {
            type: type.LOAD_TASK_COLLECTION_INFO_SUCCESS,
            payload: {
                task_collection
            }
        }
    },

    addTask: (task: any = {}) => {
        return {
            type: type.ADD_TASK,
            payload: {
                task
            }
        }
    }
}

export const ManagerTasks = action

export default {
    type,
    action
}