import { all, takeEvery, fork, put, select } from "redux-saga/effects";
import actions from "./actions";

// helper
import { APIServices, NotificationsService, setLocalData } from "../../utils";
import { key_const } from "../../const";
// import { promises } from 'dns';
// import { RealtimeActions } from '../realtime/actions';

function* saga_Login(action: any) {
    try {
        const { username, password } = action.payload;

        let LoginResponse: Promise<any> = yield APIServices.Auth.login(
            username,
            password
        );
        let response: any = LoginResponse;
        response = response.data;

        if (response.access_token) {
            let sessionKey = response.access_token;
            setLocalData(key_const.session_key, sessionKey);
            const userInfo = response.user;

            // login success
            yield put(
                actions.action.updateState({
                    session_key: sessionKey,
                    isLoggedIn: true,
                    isLoading: false,
                    userInfo,
                    current_user_info: userInfo,
                })
            );

            NotificationsService.success("Đăng nhập thành công", "Thông báo");
        } else NotificationsService.error("Đăng nhập thất bại");
    } catch (ex: any) {
        console.log("[Auth] Login Error : ", ex.message);

        // login error
        yield put(
            actions.action.updateState({
                session_key: null,
                isLoggedIn: false,
                userInfo: null,
                isLoading: false,
            })
        );

        // if (Object.prototype.hasOwnProperty.call(ex, 'message')) {
        //     NotificationsService.error(ex.message);
        //     return;
        // }

        NotificationsService.error(
            "Tài khoản chưa chính xác",
            "Đăng nhập thất bại"
        );
    }
}

function* saga_Logout() {
    try {
        setLocalData(key_const.session_key, null);
        NotificationsService.success(
            "Đăng xuất thành công",
            "Tạm biệt",
            "top-center"
        );

        yield put(
            actions.action.updateState({
                session_key: null,
                isLoggedIn: false,
                userInfo: null,
                isLoading: false,
            })
        );

        // yield put(
        //     RealtimeActions.disconnect()
        // )
    } catch (ex: any) {
        console.log("[Auth] Logout Error : ", ex.message);
    }
}

function* saga_CheckSessionKey() {
    try {
        let _req: Promise<any> = yield APIServices.Auth.getUserInfo();
        let req: any = _req;

        let userInfo = req.data;

        yield put(
            actions.action.updateState({
                userInfo,
                current_user_info: userInfo,
            })
        );
    } catch (ex: any) {
        console.log("[Auth] saga_CheckSessionKey error : ", ex.message);

        yield put(
            actions.action.updateState({
                session_key: null,
                isLoggedIn: false,
                userInfo: null,
            })
        );
        setLocalData(key_const.session_key, null);
    }
}

function* saga_ChangePassword(action: any) {
    try {
        let { old_password, new_password } = action.payload;
        yield APIServices.Auth.changeMyPassword(old_password, new_password);

        NotificationsService.success("Đổi mật khẩu thành công");
    } catch (ex: any) {
        console.log("[Auth] saga_ChangePassworderror : ", ex.message);

        NotificationsService.error("Đổi mật khẩu thất bại");
    }
}

function* saga_SaveCurrentUserInfo(action: any) {
    try {
        let _user: Promise<any> = yield select(
            (state: any) => state.auth.userInfo
        );
        let user: any = _user;

        yield APIServices.Auth.updateUserInfo(user);

        NotificationsService.success("Cập nhật thông tin cá nhân thành công");

        yield put(actions.action.checkSession());
    } catch (ex: any) {
        console.log("[Auth] saga_SaveCurrentUserInfo : ", ex.message);

        NotificationsService.error("Cập nhật thông tin cá nhân thất bại");
    }
}

function* listen() {
    yield takeEvery(actions.type.LOGIN, saga_Login);

    yield takeEvery(actions.type.CHECK_SESSION, saga_CheckSessionKey);
    yield takeEvery(actions.type.LOGOUT, saga_Logout);

    yield takeEvery(actions.type.CHANGE_PASSWORD, saga_ChangePassword);

    yield takeEvery(
        actions.type.SAVE_CURRENT_USER_INFO,
        saga_SaveCurrentUserInfo
    );
}

export default function* authSaga() {
    yield all([fork(listen)]);
}
