export const LikePayload = {
    post_id: '',
    number: 0,
};

export const CommentPayload = {
    post_id: '',
    content: '',
};

export const SharePayload = {
    post_id: '',
    content: '',
};

export enum TaskTypeEnum {
    LIKE_POST = 'like_post',
    COMMENT_POST = 'comment_post',
    SHARE_POST = 'share_post',
}

export const GetPayloadByTaskType = (type: any = TaskTypeEnum.LIKE_POST) => {
    switch (type) {
        case TaskTypeEnum.COMMENT_POST:
            return CommentPayload;

        case TaskTypeEnum.LIKE_POST:
            return LikePayload;

        case TaskTypeEnum.SHARE_POST:
            return SharePayload;

        default:
            return {};
    }
};

export const SetPayloadByType = (type: any = TaskTypeEnum.LIKE_POST, values: any = {}) => {
    switch (type){
        case TaskTypeEnum.COMMENT_POST:
            return {
                post_id: values.post_id,
                content: values.content
            }

        case TaskTypeEnum.SHARE_POST:
            return {
                post_id: values.post_id,
                content: values.content
            }

        case TaskTypeEnum.LIKE_POST:
            return {
                post_id: values.post_id,
                number: values.number
            }

        default:
            return {};
    }
}
