import React from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import { FiSearch } from "react-icons/fi";
import { BiReset } from 'react-icons/bi'
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";
import { showConfirm } from "../../components";

export const TopBarControl = (props: any = {}) => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    const setModalCurrentAccountState = (state: any) => {
        dispatch(
            actions.ManagerAccountsActions.setModalCurrentAccountState(state)
        );
    };

    // list account
    const textSearch = useSelector(
        (state: any) => state.manager_accounts.textSearch
    );

    return (
        <div className="MUP_ActionControl">
            <div style={{
                display: 'flex',
                flexDirection: 'row'
            }}>
                <div
                    className="MUP_AddButton"
                    style={{
                        backgroundColor: "#3399ff",
                        color: "white",
                        marginRight: 5
                    }}
                    onClick={() => {
                        dispatch(
                            actions.ManagerAccountsActions.setDefaultCurrentAccount()
                        );
                        setModalCurrentAccountState(true);
                    }}
                >
                    <AiOutlineUserAdd className="Icon" />
                    Thêm tài khoản
                </div>
                <div
                    className="MUP_AddButton"
                    style={{
                        backgroundColor: "#3399ff",
                        color: "white",
                    }}
                    onClick={() => {
                        showConfirm({
                            title: "Bạn chắc chắn reset trạng thái tài khoản?",
                            content: "",
                            onOk: () => {
                                dispatch(actions.ManagerAccountsActions.resetStatusAllAccount())
                            },
                            onCancel: () => {},
                        });
                    }}
                >
                    <BiReset className="Icon" />
                    Reset trạng thái tài khoản
                </div>
            </div>
            <div className="MUP_SearchBox">
                <input
                    type="text"
                    value={textSearch}
                    onChange={(e) => {
                        dispatch(
                            actions.ManagerAccountsActions.updateTextSearch(
                                e.target.value
                            )
                        );
                    }}
                    placeholder="Nhập để tìm kiếm"
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            dispatch(
                                actions.ManagerAccountsActions.searchAccount()
                            );
                        }
                    }}
                />
                <FiSearch
                    className="MUPS_Icon"
                    onClick={() => {
                        dispatch(
                            actions.ManagerAccountsActions.searchAccount()
                        );
                    }}
                />
            </div>
        </div>
    );
};
