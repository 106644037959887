import { all } from "redux-saga/effects";

import authSaga from "./auth/saga";
import userSaga from "./manager-users/saga";
import accountSaga from './manager-fb-account/saga'
import botSaga from './manager-bots/saga'
import dashboardSaga from './dashboard/saga'
import taskCollectionSaga from './manager-task-collections/saga'
import taskSaga from './manager-tasks/saga'

export default function* rootSaga() {
    yield all([
        authSaga(),
        userSaga(),
        accountSaga(),
        botSaga(),
        dashboardSaga(),
        taskCollectionSaga(), 
        taskSaga()
    ]);
}
