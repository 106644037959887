import React, { useEffect } from "react";

import "./index.scss";

import { useDispatch } from "react-redux";
import { useActions } from "../../redux";
import { Pagination } from "./pagination";
import { TableListBots } from "./table-list_bots";
import { TopBarControl } from "./top-bar-control";
import { ModalCurrentBot } from "./modal-current-bot";

export const ManagerBotPage = () => {
    // redux
    const dispatch = useDispatch();
    const actions = useActions();

    // load
    useEffect(() => {
        dispatch(actions.ManagerBotsActions.loadListBots());
    }, []);


    return (
        <div className="ManagerBotPage">
            <TopBarControl />
            <TableListBots />
            <Pagination />
            <ModalCurrentBot />
        </div>
    );
};
