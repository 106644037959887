import React from "react";
import { Link, useLocation } from "react-router-dom";
import { RouterLinkPrefix, RouterLinks } from "../../const";

import { Layout, Menu } from "antd";
import { FaUserFriends, FaChartBar, FaRobot, FaTasks } from "react-icons/fa";

import "./sidebar.scss";
import { useDispatch, useSelector } from "react-redux";
import { useActions } from "../../redux";
import { AiFillFacebook } from "react-icons/ai";

const { Sider } = Layout;

export const SideBar = () => {
    const collapsed: any = useSelector(
        (state: any) => state.ui_global.collapse
    );

    const dispatch = useDispatch();
    const actions = useActions();

    const setCollapsed = (value: any) => {
        dispatch(actions.UIGlobalActions.SetCollapse(value));
    };

    const onCollapse = (ab: any) => {
        setCollapsed(ab);
    };

    let theme: any = "dark";
    let mode: any = "inline";

    // location
    let location = useLocation();
    let current = location.pathname;

    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={onCollapse}
            width={250}
            className="Sidebar"
        >
            <div
                className="Logo"
                style={{
                    fontSize: collapsed ? 16 : 18,
                }}
                onClick={() => {
                    setCollapsed(!collapsed);
                }}
            >
                {collapsed ? "B8" : "BOT-MANAGER"}
            </div>
            <Menu theme={theme} defaultSelectedKeys={[current]} mode={mode}>
                <Menu.Item key={RouterLinks.HOME_PAGE} icon={<FaChartBar />}>
                    <Link to={RouterLinks.HOME_PAGE}>Trang chủ</Link>
                </Menu.Item>

                <Menu.Item
                    key={RouterLinks.MANAGER_USER}
                    icon={<FaUserFriends />}
                >
                    <Link to={RouterLinks.MANAGER_USER}>
                        Quản lý người dùng
                    </Link>
                </Menu.Item>

                <Menu.Item key={RouterLinks.MANAGER_BOT} icon={<FaRobot />}>
                    <Link to={RouterLinks.MANAGER_BOT}>Quản lý bot</Link>
                </Menu.Item>

                <Menu.Item
                    key={RouterLinks.MANAGER_ACCOUNT_FACEBOOK}
                    icon={<AiFillFacebook />}
                >
                    <Link to={RouterLinks.MANAGER_ACCOUNT_FACEBOOK}>
                        Quản lý tài khoản Facebook
                    </Link>
                </Menu.Item>

                <Menu.Item key={RouterLinks.MANAGER_TASK_COLLECTIONS} icon={<FaTasks />}>
                    <Link to={RouterLinks.MANAGER_TASK_COLLECTIONS}>Quản lý công việc</Link>
                </Menu.Item>
            </Menu>
        </Sider>
    );
};
